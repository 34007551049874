#clients-container {
  height: auto;
  min-height: 100vh;
  width: 100%;
  padding: 10rem 7rem;
  background-image: url("../../../Images/Dizital/bgOne.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  font-family: fontReg;
  scroll-behavior: smooth;
}
#clients-container #cli-title {
  font-size: 3rem;
}
#clients-container #cli-lists-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
}
#clients-container #cli-lists-container .cli-lists {
  display: flex;
  position: relative;
  width: 32.5%;
  font-size: 1.1rem;
  letter-spacing: 0.2px;
  color: grey;
  padding: 0.5rem 0.5rem 0.5rem 0;
}
#clients-container #cli-lists-container .cli-lists .cli-lists-bullet {
  padding: 0 1rem 0 0rem;
}

@media screen and (max-width: 600px) {
  #clients-container {
    padding: 7rem 1.5rem;
  }
  #clients-container #cli-title {
    font-size: 2.5rem;
  }
  #clients-container #cli-lists-container {
    display: block;
    height: auto;
    width: 100%;
    padding: 1rem 0.5rem;
  }
  #clients-container #cli-lists-container .cli-lists {
    display: flex;
    position: relative;
    width: 100%;
    font-size: 1.1rem;
    letter-spacing: 0.2px;
    color: grey;
    padding: 0.5rem 0.5rem 0.5rem 0;
  }
  #clients-container #cli-lists-container .cli-lists .cli-lists-bullet {
    padding: 0 1rem 0 0rem;
  }
}
