#no-header-container {
  position: fixed;
  z-index: 15;
}
#no-header-container #back-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1.75rem;
  right: 1.5rem;
  padding: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transform: rotate(180deg);
  transition: background-color 0.1s ease-in-out;
  background-color: var(--border-one);
}
#no-header-container #back-to-top:hover {
  background-color: var(--footer-one);
}

#header-container {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 6rem;
  width: 100%;
  background-color: transparent;
  z-index: 15;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 2px 2px 4px 0 #eee;
}
#header-container #back-to-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1.75rem;
  right: 1.5rem;
  padding: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transform: rotate(180deg);
  transition: background-color 0.1s ease-in-out;
  background-color: var(--border-one);
}
#header-container #back-to-top:hover {
  background-color: var(--footer-one);
}
#header-container #header-left-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 9.5rem;
  height: 100%;
  width: 35%;
  box-sizing: border-box;
}
#header-container #header-left-container #header-left-logo-icon {
  height: 4rem;
  width: auto;
  color: var(--theme-two);
  cursor: pointer;
}
#header-container #header-left-container #header-left-logo-text {
  font-size: 2rem;
  font-weight: 900;
  color: var(--theme-one);
}
#header-container #header-right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 65%;
}
#header-container #header-right-container .header-right-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0 1rem;
  border-radius: 0.4rem;
  font-size: 1.2rem;
  font-family: fontLight;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
#header-container #header-right-container .header-right-menu:last-child {
  height: 60%;
  width: 10rem;
  color: white;
  border-radius: 0.7rem;
  margin-left: 1rem;
  background-color: var(--theme-three);
}
#header-container #header-right-container .header-right-menu:hover {
  color: var(--theme-two);
}
#header-container #header-right-container .header-right-drop-box {
  position: absolute;
  top: 100%;
  left: 0.7rem;
  height: auto;
  width: 20rem;
  box-sizing: border-box;
}
#header-container #header-right-container .header-right-drop-box .header-right-dropp {
  position: absolute;
  left: 0;
  height: auto;
  padding: 0.5rem 0.5rem;
  width: 100%;
  background-color: var(--theme-three);
  border-radius: 0.7rem;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  box-sizing: border-box;
}
#header-container #header-right-container .header-right-drop-box .header-right-dropp .header-right-drop-elements {
  position: relative;
  height: 1.5rem;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0rem;
  font-size: 1.05rem;
  color: white;
  transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
}
#header-container #header-right-container .header-right-drop-box .header-right-dropp .header-right-drop-elements:hover {
  padding-left: 1rem;
  color: var(--theme-two);
}
#header-container #header-right-container .header-right-drop-box .header-right-dropp .header-right-drop-elements:hover::before {
  opacity: 1;
}
#header-container #header-right-container .header-right-drop-box .header-right-dropp .header-right-drop-elements::before {
  content: "-- ";
  height: auto;
  width: 1rem;
  color: var(--theme-two);
  opacity: 0;
  margin-left: -1rem;
  margin-right: 0.5rem;
  transition: opacity 0.3s ease-in-out;
}
#header-container #header-right-container .header-right-drop-box .header-right-drop-show {
  top: 0;
  opacity: 0;
  animation-name: drop-menu-show;
}
@keyframes drop-menu-show {
  100% {
    opacity: 1;
    top: 0;
  }
}
#header-container #header-right-container .header-right-drop-box .header-right-drop-hide {
  top: 0;
  opacity: 1;
  animation-name: drop-menu-hide;
}
@keyframes drop-menu-hide {
  100% {
    opacity: 0;
    top: 0;
  }
}

@media screen and (max-width: 1025px) {
  #no-header-container #back-to-top {
    right: 2rem;
    bottom: 2rem;
    padding: 0.75rem;
  }

  #header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    height: 6rem;
    width: 100%;
    background-color: white;
    z-index: 12;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 2px 2px 4px 0 #eee;
    box-sizing: border-box;
  }
  #header-container #back-to-top {
    right: 2rem;
    bottom: 2rem;
    padding: 0.75rem;
  }
  #header-container #header-left-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 75%;
    padding-left: 1.5rem;
    box-sizing: border-box;
  }
  #header-container #header-left-container #header-left-logo-icon {
    height: 4rem;
    width: auto;
    color: var(--theme-two);
  }
  #header-container #header-left-container #header-left-logo-text {
    font-size: 1.6rem;
    font-weight: 900;
    color: var(--theme-one);
  }
  #header-container #header-p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15%;
  }
  #header-container #header-p img {
    height: 2rem;
    cursor: pointer;
  }
  #header-container #header-two-right-menu-drop {
    position: fixed;
    height: calc(100vh - 6rem);
    width: 100%;
    top: 6rem;
    left: 0;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top {
    height: 80%;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements {
    height: auto;
    padding: 1rem 0.5rem;
    font-size: 1.5rem;
    color: #fff;
    font-family: fontLight;
    transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements:hover {
    padding-left: 1rem;
    color: var(--theme-two);
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements:hover::before {
    opacity: 1;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements::before {
    content: "-- ";
    height: auto;
    width: 1rem;
    color: var(--theme-two);
    opacity: 0;
    margin-left: -1rem;
    margin-right: 0.5rem;
    transition: opacity 0.3s ease-in-out;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box {
    position: relative;
    height: auto;
    width: 100%;
    padding: 1rem 0rem 0 1rem;
    box-sizing: border-box;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp {
    height: auto;
    width: 100%;
    transition: height 0.4s ease-in-out;
    animation-duration: 0.4s;
    animation-fill-mode: both;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements {
    padding: 0.5rem 0;
    font-size: 1.5rem;
    color: #fff;
    transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements:hover {
    padding-left: 1rem;
    color: var(--theme-two);
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements:hover::before {
    opacity: 1;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements::before {
    content: "-- ";
    height: auto;
    width: 1rem;
    color: var(--theme-two);
    opacity: 0;
    margin-left: -1rem;
    margin-right: 0.5rem;
    transition: opacity 0.3s ease-in-out;
  }
  #header-container .header-drop-menu {
    position: relative;
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 100% 0%;
    background-color: var(--theme-three);
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: header-menu-mount;
  }
  #header-container .header-hide-menu {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: 100% 0%;
    background-color: var(--theme-three);
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: header-menu-unmount;
  }
  @keyframes header-menu-mount {
    50% {
      transform: scale(1, 0);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  @keyframes header-menu-unmount {
    50% {
      transform: scale(1, 0);
      opacity: 1;
    }
    100% {
      transform: scale(0, 0);
      opacity: 0;
    }
  }
}
@media screen and (max-width: 600px) {
  #no-header-container #back-to-top {
    right: 1rem;
    bottom: 1rem;
    padding: 0.25rem;
  }

  #header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    height: 4.5rem;
    width: 100%;
    background-color: white;
    z-index: 12;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 2px 2px 4px 0 #eee;
    box-sizing: border-box;
  }
  #header-container #back-to-top {
    right: 1rem;
    bottom: 1rem;
    padding: 0.25rem;
  }
  #header-container #header-left-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 75%;
    padding-left: 1.5rem;
    box-sizing: border-box;
  }
  #header-container #header-left-container #header-left-logo-icon {
    height: 3rem;
    width: auto;
    color: var(--theme-two);
  }
  #header-container #header-left-container #header-left-logo-text {
    font-size: 1.6rem;
    font-weight: 900;
    color: var(--theme-one);
  }
  #header-container #header-p {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 20%;
  }
  #header-container #header-p img {
    height: unset;
    cursor: pointer;
  }
  #header-container #header-two-right-menu-drop {
    position: fixed;
    height: calc(100% - 4.5rem);
    width: 100%;
    top: 4.5rem;
    left: 0;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top {
    height: 80%;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements {
    height: auto;
    padding: 1rem 0.5rem;
    font-size: 1.05rem;
    color: #fff;
    font-family: fontLight;
    transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements:hover {
    padding-left: 1rem;
    color: var(--theme-two);
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements:hover::before {
    opacity: 1;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements::before {
    content: "-- ";
    height: auto;
    width: 1rem;
    color: var(--theme-two);
    opacity: 0;
    margin-left: -1rem;
    margin-right: 0.5rem;
    transition: opacity 0.3s ease-in-out;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box {
    position: relative;
    height: auto;
    width: 100%;
    padding: 1rem 0rem 0 1rem;
    box-sizing: border-box;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp {
    height: auto;
    width: 100%;
    transition: height 0.4s ease-in-out;
    animation-duration: 0.4s;
    animation-fill-mode: both;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements {
    padding: 0.5rem 0;
    font-size: unset;
    color: #fff;
    transition: padding-left 0.3s ease-in-out, color 0.3s ease-in-out;
    cursor: pointer;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements:hover {
    padding-left: 1rem;
    color: var(--theme-two);
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements:hover::before {
    opacity: 1;
  }
  #header-container #header-two-right-menu-drop .phone-menu-top .phone-two-menu-elements .phone-two-menu-drop-box .phone-two-menu-dropp .phone-two-menu-drop-elements::before {
    content: "-- ";
    height: auto;
    width: 1rem;
    color: var(--theme-two);
    opacity: 0;
    margin-left: -1rem;
    margin-right: 0.5rem;
    transition: opacity 0.3s ease-in-out;
  }
  #header-container #header-two-right-menu-drop .phone-menu-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 20%;
    width: 100%;
    padding: 0rem 2rem;
    box-sizing: border-box;
  }
  #header-container #header-two-right-menu-drop .phone-menu-bottom .header-one-social-icon {
    height: 1.5rem;
    width: 1.5rem;
    padding-right: 2rem;
    cursor: pointer;
  }
  #header-container #header-two-right-menu-drop .phone-menu-bottom .header-one-social-icon:hover {
    filter: grayscale(100%);
  }
  #header-container .header-drop-menu {
    position: relative;
    opacity: 0;
    transform: scale(0, 0);
    transform-origin: 100% 0%;
    background-color: var(--theme-three);
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: header-menu-mount;
  }
  #header-container .header-hide-menu {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: 100% 0%;
    background-color: var(--theme-three);
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: header-menu-unmount;
  }
  @keyframes header-menu-mount {
    50% {
      transform: scale(1, 0);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
  @keyframes header-menu-unmount {
    50% {
      transform: scale(1, 0);
      opacity: 1;
    }
    100% {
      transform: scale(0, 0);
      opacity: 0;
    }
  }
}
