#about-container {
  display: block;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 10rem 7rem 7rem 7rem;
  background-image: url("../../../Images/Dizital/bgNine.svg");
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}
#about-container #about-title {
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#about-container #about-mid-box {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
#about-container #about-mid-box #about-mid-left {
  position: relative;
  left: -15rem;
  top: 0;
  height: 100%;
  width: 50%;
  padding: 3rem 0rem;
  opacity: 0;
  box-sizing: border-box;
  animation-name: about-left;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#about-container #about-mid-box #about-mid-left #about-mid-subtitle {
  height: auto;
  width: 100%;
  padding: 1rem 0 0 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  word-spacing: 1.5px;
  letter-spacing: 0.4px;
  font-family: arialLight;
  box-sizing: border-box;
  text-align: justify;
}
#about-container #about-mid-box #about-mid-right {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  right: -15rem;
  top: -5rem;
  height: 100%;
  width: 50%;
  opacity: 0;
  animation-name: about-right;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#about-container #about-mid-box #about-mid-right-img {
  height: 35rem;
  animation-name: about-right-img;
  animation-duration: 7s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
@keyframes about-right {
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes about-left {
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes about-right-img {
  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@media screen and (max-width: 1025px) {
  #about-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 10rem 2rem 3rem 2rem;
    background-image: url("../../../Images/Waves/get-in-touch.svg");
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
  }
  #about-container #about-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
  }
  #about-container #about-mid-box {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }
  #about-container #about-mid-box #about-mid-left {
    position: relative;
    left: -15rem;
    top: 0;
    order: 2;
    height: auto;
    width: 100%;
    padding: 0rem 0rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: about-left;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #about-container #about-mid-box #about-mid-left #about-mid-subtitle {
    font-family: arialLight;
    padding: 2rem 0.3rem;
    letter-spacing: 0.75px;
    line-height: 2.5rem;
    font-size: 1.7rem;
    box-sizing: border-box;
  }
  #about-container #about-mid-box #about-mid-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    opacity: 0;
    animation-name: about-right;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #about-container #about-mid-box #about-mid-right-img {
    height: 30rem;
    margin: -3rem 0;
    animation-name: about-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  @keyframes about-right {
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes about-left {
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes about-right-img {
    50% {
      transform: translateY(1rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }
}
@media screen and (max-width: 600px) {
  #about-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 7rem 1.5rem 3rem 1.5rem;
    background-image: url("../../../Images/Waves/get-in-touch.svg");
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
  }
  #about-container #about-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
  }
  #about-container #about-mid-box {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }
  #about-container #about-mid-box #about-mid-left {
    position: relative;
    left: -15rem;
    top: 0;
    order: 2;
    height: auto;
    width: 100%;
    padding: 0rem 0rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: about-left;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #about-container #about-mid-box #about-mid-left #about-mid-subtitle {
    font-family: arialLight;
    padding: 2rem 0.3rem;
    letter-spacing: 0.5px;
    line-height: 1.5rem;
    font-size: 1.2rem;
    box-sizing: border-box;
  }
  #about-container #about-mid-box #about-mid-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    opacity: 0;
    animation-name: about-right;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #about-container #about-mid-box #about-mid-right-img {
    height: 23rem;
    margin: -3rem 0;
    animation-name: about-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  @keyframes about-right {
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes about-left {
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes about-right-img {
    50% {
      transform: translateY(1rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }
}
