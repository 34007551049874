#home-banner {
  position: absolute;
  top: 0rem;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
}
#home-banner .home-banner-img {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
#home-banner .home-banner-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3137254902);
  backdrop-filter: blur(5px);
}
#home-banner #home-banner-one-show {
  left: 0;
  width: 0%;
  animation-name: one-show;
}
#home-banner #home-banner-one-hide {
  left: 0;
  width: 100%;
  animation-name: one-hide;
}
@keyframes one-show {
  100% {
    width: 100%;
  }
}
@keyframes one-hide {
  100% {
    width: 0;
  }
}
#home-banner #home-banner-two-show {
  right: 0;
  width: 0%;
  animation-name: two-show;
}
#home-banner #home-banner-two-hide {
  right: 0;
  width: 100%;
  animation-name: two-hide;
}
@keyframes two-show {
  100% {
    width: 100%;
  }
}
@keyframes two-hide {
  100% {
    width: 0;
  }
}
#home-banner #home-banner-top {
  height: auto;
  width: 60%;
  padding: 10rem 0 5rem 9.5rem;
  box-sizing: border-box;
}
#home-banner #home-banner-top #home-banner-top-box {
  position: relative;
  height: auto;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  overflow-y: hidden;
}
#home-banner #home-banner-top #home-banner-top-box .home-banner-top-title {
  position: relative;
  top: 10rem;
  height: auto;
  opacity: 0;
  font-size: 4rem;
  line-height: 4rem;
  font-family: fontReg;
  color: white;
  animation-name: one-top-title;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  box-sizing: border-box;
}
#home-banner #home-banner-top #home-banner-top-box .home-banner-top-subtitle {
  position: relative;
  top: 13rem;
  opacity: 0;
  height: auto;
  margin-top: 1.5rem;
  font-size: 2rem;
  color: white;
  line-height: 2rem;
  animation-name: one-top-subtitle;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  box-sizing: border-box;
}
@keyframes one-top-title {
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes one-top-subtitle {
  100% {
    top: 0rem;
    opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  #home-banner {
    position: absolute;
    top: 0rem;
    left: 0;
    height: 100vh;
    width: 100%;
    padding: 0 1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 10rem;
    padding-bottom: 0rem;
    box-sizing: border-box;
  }
  #home-banner .home-banner-img {
    height: 100vh;
    background-size: cover;
    background-position: top;
  }
  #home-banner #home-banner-top {
    height: 100vh;
    width: 100%;
    padding: 1rem 0 2rem 1rem;
  }
  #home-banner #home-banner-top #home-banner-top-box {
    height: 15rem;
    width: 60%;
  }
  #home-banner #home-banner-top #home-banner-top-box .home-banner-top-title {
    font-size: 3rem;
    line-height: 3rem;
  }
  #home-banner #home-banner-top #home-banner-top-box .home-banner-top-subtitle {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 1025px) and (max-height: 700px) {
  #home-banner {
    height: 40rem;
    padding-top: 7rem;
    padding-bottom: 0rem;
    box-sizing: border-box;
  }
  #home-banner .home-banner-img {
    height: 100%;
  }
  #home-banner #home-banner-top {
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  #home-banner {
    position: absolute;
    top: 0rem;
    left: 0;
    height: 100vh;
    width: 100%;
    padding: 0 1rem;
    padding-top: 7rem;
  }
  #home-banner .home-banner-img {
    height: 100vh;
  }
  #home-banner #home-banner-top {
    height: 100vh;
    width: 90%;
    padding: 1rem 0 1rem 0;
  }
  #home-banner #home-banner-top #home-banner-top-box {
    height: 11rem;
    width: 90%;
  }
  #home-banner #home-banner-top #home-banner-top-box .home-banner-top-title {
    font-size: 2rem;
    line-height: 2.3rem;
  }
  #home-banner #home-banner-top #home-banner-top-box .home-banner-top-subtitle {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: 0.75rem;
  }
}
@media screen and (max-width: 600px) and (max-height: 700px) {
  #home-banner {
    height: 40rem;
    padding-top: 7rem;
    padding-bottom: 0rem;
    box-sizing: border-box;
  }
  #home-banner .home-banner-img {
    height: 100%;
  }
  #home-banner #home-banner-top {
    height: 100%;
  }
}
