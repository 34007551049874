#ser-container {
  height: auto;
  width: auto;
  padding: 5rem 5rem 1rem 5rem;
  background-image: url("../../../Images/Dizital/bgOne.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 8rem 5rem 5rem 5rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 2px 2px 25px 0px var(--shadow);
}
#ser-container #ser-title {
  font-family: fontReg;
  font-size: 3rem;
}
#ser-container #ser-img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
}
#ser-container #ser-img-cont #ser-img {
  height: 20rem;
  width: auto;
  margin: 0rem auto;
}
#ser-container #ser-subtitle {
  font-family: arialLight;
  font-size: 1.3rem;
  text-indent: 3rem;
  padding-bottom: 1rem;
  letter-spacing: 0px;
  word-spacing: 1.5px;
  line-height: 1.8rem;
  text-align: justify;
}
#ser-container #ser-lists-container {
  height: auto;
  width: 100%;
  margin: 3rem 0;
}
#ser-container #ser-lists-container .ser-lists-title {
  font-family: fontReg;
  font-size: 2rem;
  color: var(--theme-three);
}
#ser-container #ser-lists-container .ser-lists-subtitle-container {
  height: auto;
  width: 100%;
  padding: 1rem;
}
#ser-container #ser-lists-container .ser-lists-subtitle-container .ser-lists-subtitle {
  display: flex;
  position: relative;
  font-size: 1.2rem;
  word-spacing: 1.5px;
  font-family: arialReg;
  letter-spacing: 0.1px;
  padding: 0.5rem 0.5rem 0.5rem 0;
}
#ser-container #ser-lists-container .ser-lists-subtitle-container .ser-lists-subtitle .ser-lists-bullet {
  padding: 0 1rem 0 0rem;
}
#ser-container #ser-lists-container .ser-list-cont-column {
  display: flex;
  flex-wrap: wrap;
}
#ser-container #ser-lists-container .ser-list-cont-column .ser-list-column {
  width: 32.5%;
}
#ser-container #ser-subcontent-container {
  height: auto;
  width: 100%;
  margin: 4rem 0;
}
#ser-container #ser-subcontent-container .ser-subcontent-title {
  font-family: fontReg;
  font-size: 2rem;
  color: var(--theme-three);
}
#ser-container #ser-subcontent-container .ser-subcontent-subtitle {
  margin: 1.5rem 0;
  position: relative;
  font-size: 1.3rem;
  letter-spacing: 0.1px;
  text-indent: 3rem;
  line-height: 1.8rem;
  font-family: arialLight;
  text-align: justify;
}
#ser-container #ser-software-container {
  height: auto;
  width: 100%;
  margin: 4rem 0 0 0;
  font-size: 1.3rem;
  font-family: arialLight;
  letter-spacing: 0px;
}
#ser-container #ser-software-container #ser-tech-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  padding: 0.5rem;
  margin: 3rem auto 0 auto;
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements {
  position: relative;
  height: 8rem;
  width: 10rem;
  font-family: arialLight;
  margin: 0 0.75rem 0.75rem 0;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 3px 3px 10px var(--shadow-two);
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:hover {
  transform: scale(1.03);
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(1) .ser-tech-icon {
  background-image: url("../../../Images/Tech/one.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(2) .ser-tech-icon {
  background-image: url("../../../Images/Tech/two.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(3) .ser-tech-icon {
  background-image: url("../../../Images/Tech/one.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(4) .ser-tech-icon {
  background-image: url("../../../Images/Tech/three.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(5) .ser-tech-icon {
  background-image: url("../../../Images/Tech/four.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(6) .ser-tech-icon {
  background-image: url("../../../Images/Tech/five.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(7) .ser-tech-icon {
  background-image: url("../../../Images/Tech/six.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(8) .ser-tech-icon {
  background-image: url("../../../Images/Tech/seven.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements:nth-child(9) .ser-tech-icon {
  background-image: url("../../../Images/Tech/eight.png");
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements .ser-tech-icon {
  height: 4.5rem;
  width: 100%;
  margin-top: 1rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
#ser-container #ser-software-container #ser-tech-list .ser-tech-elements .ser-tech-title {
  text-align: center;
  padding-top: 0.5rem;
}

@media screen and (max-width: 1025px) {
  #ser-container {
    height: auto;
    width: auto;
    padding: 10rem 2rem 3rem 2rem;
    margin: 0;
    background-image: url("../../../Images/Dizital/bgOne.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  #ser-container #ser-title {
    font-size: 3rem;
  }
  #ser-container #ser-img-cont #ser-img {
    height: 27rem;
    margin-top: 0rem;
  }
  #ser-container #ser-subtitle {
    font-size: 1.75rem;
    letter-spacing: 0.75px;
    line-height: 2.5rem;
  }
  #ser-container #ser-lists-container {
    height: auto;
    width: 100%;
    margin: 3rem 0;
  }
  #ser-container #ser-lists-container .ser-lists-title {
    font-size: 2.5rem;
  }
  #ser-container #ser-lists-container .ser-lists-subtitle-container {
    height: auto;
    width: 100%;
    padding: 1rem 0;
  }
  #ser-container #ser-lists-container .ser-lists-subtitle-container .ser-lists-subtitle {
    display: flex;
    position: relative;
    font-size: 1.6rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  #ser-container #ser-lists-container .ser-lists-subtitle-container .ser-lists-subtitle .ser-lists-bullet {
    padding: 0 1rem 0 0rem;
  }
  #ser-container #ser-subcontent-container {
    height: auto;
    width: 100%;
    margin: 3rem 0;
  }
  #ser-container #ser-subcontent-container .ser-subcontent-title {
    font-size: 2.5rem;
  }
  #ser-container #ser-subcontent-container .ser-subcontent-subtitle {
    margin: 1.5rem 0;
    position: relative;
    font-size: 1.75rem;
    text-indent: 2rem;
    letter-spacing: 0.75px;
    line-height: 2.5rem;
  }
  #ser-container #ser-software-container {
    height: auto;
    width: 100%;
    margin: 4rem 0 0 0;
    font-family: arialLight;
    font-size: 1.75rem;
    letter-spacing: 0.75px;
    word-spacing: 0;
    line-height: 2.5rem;
  }
  #ser-container #ser-software-container #ser-tech-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    padding: 0.5rem;
    margin: 3rem auto 0 auto;
  }
  #ser-container #ser-software-container #ser-tech-list .ser-tech-elements {
    position: relative;
    height: 10rem;
    width: 12rem;
    margin: 0 1.25rem 1.25rem 0;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 3px 3px 10px var(--shadow-two);
    box-sizing: border-box;
  }
  #ser-container #ser-software-container #ser-tech-list .ser-tech-elements .ser-tech-icon {
    height: 5rem;
    width: 100%;
    margin-top: 1.5rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
  }
  #ser-container #ser-software-container #ser-tech-list .ser-tech-elements .ser-tech-title {
    text-align: center;
    font-size: 1.75rem;
    padding-top: 0.5rem;
  }
}
@media screen and (max-width: 600px) {
  #ser-container {
    height: auto;
    width: auto;
    padding: 7rem 1.5rem 3rem 1.5rem;
    margin: 0;
    background-image: url("../../../Images/Dizital/bgOne.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  #ser-container #ser-title {
    font-size: 2.5rem;
  }
  #ser-container #ser-img-cont #ser-img {
    height: 25rem;
    margin-top: 0;
  }
  #ser-container #ser-subtitle {
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    line-height: 1.8rem;
  }
  #ser-container #ser-lists-container {
    height: auto;
    width: 100%;
    margin: 3rem 0;
  }
  #ser-container #ser-lists-container .ser-lists-title {
    font-size: 1.55rem;
  }
  #ser-container #ser-lists-container .ser-lists-subtitle-container {
    height: auto;
    width: 100%;
    padding: 1rem 0;
  }
  #ser-container #ser-lists-container .ser-lists-subtitle-container .ser-lists-subtitle {
    display: flex;
    position: relative;
    font-size: 1.2rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  #ser-container #ser-lists-container .ser-lists-subtitle-container .ser-lists-subtitle .ser-lists-bullet {
    padding: 0 1rem 0 0rem;
  }
  #ser-container #ser-subcontent-container {
    height: auto;
    width: 100%;
    margin: 3rem 0;
  }
  #ser-container #ser-subcontent-container .ser-subcontent-title {
    font-size: 1.75rem;
  }
  #ser-container #ser-subcontent-container .ser-subcontent-subtitle {
    margin: 1.5rem 0;
    position: relative;
    font-size: 1.3rem;
    line-height: 1.8rem;
    text-indent: 2rem;
  }
  #ser-container #ser-software-container {
    height: auto;
    width: 100%;
    margin: 4rem 0 0 0;
    font-family: arialLight;
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    word-spacing: 0;
    line-height: 1.8rem;
  }
  #ser-container #ser-software-container #ser-tech-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    padding: 0.5rem;
    margin: 3rem auto 0 auto;
  }
  #ser-container #ser-software-container #ser-tech-list .ser-tech-elements {
    position: relative;
    height: 8rem;
    width: 9rem;
    margin: 0 1.25rem 1.25rem 0;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 3px 3px 10px var(--shadow-two);
    box-sizing: border-box;
  }
  #ser-container #ser-software-container #ser-tech-list .ser-tech-elements .ser-tech-icon {
    height: 4.5rem;
    width: 100%;
    margin-top: 1rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
  }
  #ser-container #ser-software-container #ser-tech-list .ser-tech-elements .ser-tech-title {
    text-align: center;
    font-size: 1.2rem;
    padding-top: 0.5rem;
  }
}
