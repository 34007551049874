#footer-container {
  position: relative;
  height: 70vh;
  width: 100%;
  background-color: var(--footer-one);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 0rem 0rem 0 0;
}
#footer-container #footer-top-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -5rem;
  height: 15rem;
  width: 100%;
  margin-bottom: 10rem;
  padding: 0 3.5rem;
  box-sizing: border-box;
}
#footer-container #footer-top-container #footer-top-container-box {
  position: relative;
  display: flex;
  height: 100%;
  width: 90%;
  overflow-x: hidden;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-box {
  display: flex;
  flex-flow: row wrap;
  position: absolute;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 0;
  color: white;
  box-sizing: border-box;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 25%;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-left .footer-top-box-left-icon {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right {
  margin-top: 2rem;
  height: 5rem;
  width: 75%;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right .footer-top-box-right-title {
  font-size: 1rem;
  width: 100%;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right .footer-top-box-right-subtitle {
  font-size: 2.3rem;
  width: 100%;
  cursor: pointer;
  font-family: fontLight;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-box:first-child {
  background-color: var(--theme-three);
  left: 0;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-mount {
  animation-duration: 0.8s;
  animation-fill-mode: both;
}
#footer-container #footer-top-container #footer-top-container-box .footer-top-mount:nth-child(1) {
  animation-delay: 0s;
  animation-name: footer-top-mount-one;
}
@keyframes footer-top-mount-one {
  100% {
    width: 100%;
    opacity: 1;
  }
}
#footer-container #footer-mid-container {
  display: flex;
  justify-content: flex-start;
  height: 20rem;
  width: 100%;
  padding: 5rem 8rem;
  box-sizing: border-box;
}
#footer-container #footer-mid-container .footer-mid-box {
  height: 18rem;
  width: 20rem;
  padding-right: 3rem;
  box-sizing: border-box;
}
#footer-container #footer-mid-container .footer-mid-box:hover .footer-mid-title::after {
  width: 130%;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-title {
  position: relative;
  font-size: 1.3rem;
  width: fit-content;
  padding-bottom: 1rem;
  color: #eee;
  cursor: pointer;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-title::after {
  content: "";
  position: absolute;
  bottom: 0.2rem;
  left: 0;
  height: 2px;
  width: 0;
  background-color: var(--footer-two);
  transition: width 0.3s ease-in-out;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two {
  display: flex;
  justify-content: flex-start;
  height: 10rem;
  width: 370%;
  font-size: 1.1rem;
  color: var(--border-one);
  padding: 0;
  box-sizing: border-box;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box {
  height: 100%;
  width: 25%;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(1) {
  width: 15%;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(2) {
  width: 25%;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(3) {
  width: 30%;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(4) {
  width: 30%;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box .footer-mid-bottom-elements {
  padding: 0.5rem 1rem;
  color: #aaa;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
#footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box .footer-mid-bottom-elements:hover {
  color: white;
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom {
  height: 10rem;
  width: 90%;
  font-size: 1.1rem;
  color: var(--border-one);
  padding: 1rem 0rem 0 0;
  box-sizing: border-box;
  cursor: default;
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box {
  display: flex;
  position: relative;
  height: 3rem;
  width: 100%;
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(1) {
  padding-bottom: 1rem;
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(1) .footer-mid-one-right::before {
  top: 0.3rem;
  background-image: url("../../../Images/Footer/location.svg");
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(2) a {
  color: var(--border-one);
  text-decoration: none;
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(2) .footer-mid-one-right::before {
  background-image: url("../../../Images/Footer/call.svg");
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(3) a {
  color: var(--border-one);
  text-decoration: none;
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(3) .footer-mid-one-right::before {
  background-image: url("../../../Images/Footer/mail.svg");
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right {
  font-family: fontLight;
  font-size: 1.1rem;
  position: relative;
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right:hover::before {
  filter: invert(0%);
}
#footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right::before {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: -2.5rem;
  height: 1.5rem;
  width: 1.5rem;
  background-size: contain;
  background-position: center;
  filter: invert(45%);
  transition: filter 0.3s ease-in-out;
}
#footer-container #footer-bottom-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 8rem;
  font-size: 0.9rem;
  color: var(--border-one);
  background-color: var(--footer-two);
  box-sizing: border-box;
  letter-spacing: 0.2px;
}
#footer-container #footer-bottom-container a {
  color: var(--border-one);
  transition: color 0.2s ease-in-out;
}
#footer-container #footer-bottom-container a:hover {
  color: white;
}

@media screen and (max-width: 1025px) {
  #footer-container {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
    padding: 1rem 1rem 3rem 1rem;
    background-color: var(--theme-one);
    box-sizing: border-box;
  }
  #footer-container #footer-top-container {
    display: flex;
    justify-content: flex-start;
    position: relative;
    height: 6rem;
    width: 100%;
    padding: 0 0.7rem;
    box-sizing: border-box;
  }
  #footer-container #footer-top-container #footer-top-container-box {
    position: relative;
    display: flex;
    height: 6rem;
    width: 0;
    box-sizing: border-box;
    overflow: hidden;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box {
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    top: 0;
    opacity: 1;
    height: 100%;
    width: 100%;
    color: white;
    box-sizing: border-box;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 25%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-left .footer-top-box-left-icon {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right {
    margin-top: 1.3rem;
    height: 5rem;
    width: 75%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right .footer-top-box-right-title {
    font-size: 1rem;
    width: 100%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right .footer-top-box-right-subtitle {
    font-size: 2rem;
    width: 100%;
    cursor: pointer;
    font-family: fontLight;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box:first-child {
    background-color: var(--footer-one);
    left: 0;
  }
  #footer-container #footer-top-container .footer-top-mount-p {
    animation-duration: 0.8s;
    animation-fill-mode: both;
    animation-delay: 0s;
    animation-name: footer-top-mount-one-p;
  }
  @keyframes footer-top-mount-one-p {
    100% {
      width: 100%;
      opacity: 1;
    }
  }
  #footer-container #footer-mid-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    padding: 4rem 1.5rem;
    box-sizing: border-box;
  }
  #footer-container #footer-mid-container .footer-mid-box {
    height: auto;
    width: 30rem;
    padding-right: 0rem;
    box-sizing: border-box;
  }
  #footer-container #footer-mid-container .footer-mid-box:hover .footer-mid-title::after {
    width: 130%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-title {
    position: relative;
    font-size: 2rem;
    width: fit-content;
    margin-top: 5rem;
    padding-bottom: 1rem;
    color: #eee;
    cursor: pointer;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-title::after {
    content: "";
    position: absolute;
    bottom: 0.2rem;
    left: 0;
    height: 2px;
    width: 0;
    background-color: var(--footer-two);
    transition: width 0.3s ease-in-out;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    font-size: 1.7rem;
    color: var(--border-one);
    padding: 0;
    box-sizing: border-box;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box {
    height: 100%;
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(1) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(2) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(3) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(4) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box .footer-mid-bottom-elements {
    padding: 1rem 0rem 1rem 1rem;
    color: #aaa;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box .footer-mid-bottom-elements:hover {
    color: white;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-title {
    margin-top: 0rem;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom {
    height: 13rem;
    width: 90%;
    font-size: 1.7rem;
    color: var(--border-one);
    padding: 1rem 0rem 0 3rem;
    box-sizing: border-box;
    cursor: default;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box {
    display: flex;
    position: relative;
    height: 3rem;
    width: 100%;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(1) {
    padding-bottom: 3rem;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(1) .footer-mid-one-right::before {
    top: 0.3rem;
    background-image: url("../../../Images/Footer/location.svg");
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(2) {
    margin-bottom: 1rem;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(2) .footer-mid-one-right::before {
    background-image: url("../../../Images/Footer/call.svg");
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(3) .footer-mid-one-right::before {
    background-image: url("../../../Images/Footer/mail.svg");
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right {
    font-family: fontLight;
    font-size: 1.7rem;
    position: relative;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right:hover::before {
    filter: invert(0%);
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right::before {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: -3rem;
    height: 2rem;
    width: 2rem;
    background-size: contain;
    background-position: center;
    filter: invert(45%);
    transition: filter 0.3s ease-in-out;
  }
  #footer-container #footer-bottom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    position: absolute;
    bottom: 0;
    height: 6rem;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    color: var(--border-one);
    background-color: var(--footer-two);
    box-sizing: border-box;
  }
  #footer-container #footer-bottom-container a {
    color: var(--border-one);
  }
}
@media screen and (max-width: 600px) {
  #footer-container {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
    padding: 1rem 1rem 3rem 1rem;
    background-color: var(--theme-one);
    box-sizing: border-box;
  }
  #footer-container #footer-top-container {
    display: flex;
    justify-content: flex-start;
    position: relative;
    height: 6rem;
    width: 100%;
    padding: 0 0.7rem;
    box-sizing: border-box;
  }
  #footer-container #footer-top-container #footer-top-container-box {
    position: relative;
    display: flex;
    height: 6rem;
    width: 0;
    box-sizing: border-box;
    overflow: hidden;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box {
    display: flex;
    flex-flow: row wrap;
    position: absolute;
    top: 0;
    opacity: 1;
    height: 100%;
    width: 100%;
    color: white;
    box-sizing: border-box;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 25%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-left .footer-top-box-left-icon {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right {
    margin-top: 1.3rem;
    height: 5rem;
    width: 75%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right .footer-top-box-right-title {
    font-size: 1rem;
    width: 100%;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box .footer-top-box-right .footer-top-box-right-subtitle {
    font-size: 2rem;
    width: 100%;
    cursor: pointer;
    font-family: fontLight;
  }
  #footer-container #footer-top-container #footer-top-container-box .footer-top-box:first-child {
    background-color: var(--footer-one);
    left: 0;
  }
  #footer-container #footer-top-container .footer-top-mount-p {
    animation-duration: 0.8s;
    animation-fill-mode: both;
    animation-delay: 0s;
    animation-name: footer-top-mount-one-p;
  }
  @keyframes footer-top-mount-one-p {
    100% {
      width: 100%;
      opacity: 1;
    }
  }
  #footer-container #footer-mid-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    padding: 4rem 0.5rem;
    box-sizing: border-box;
  }
  #footer-container #footer-mid-container .footer-mid-box {
    height: auto;
    width: 20rem;
    padding-right: 0rem;
    box-sizing: border-box;
  }
  #footer-container #footer-mid-container .footer-mid-box:hover .footer-mid-title::after {
    width: 130%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-title {
    position: relative;
    font-size: 1.3rem;
    width: fit-content;
    margin-top: 3rem;
    padding-bottom: 1rem;
    color: #eee;
    cursor: pointer;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-title::after {
    content: "";
    position: absolute;
    bottom: 0.2rem;
    left: 0;
    height: 2px;
    width: 0;
    background-color: var(--footer-two);
    transition: width 0.3s ease-in-out;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    font-size: 1.1rem;
    color: var(--border-one);
    padding: 0;
    box-sizing: border-box;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box {
    height: 100%;
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(1) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(2) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(3) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box:nth-child(4) {
    width: 100%;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box .footer-mid-bottom-elements {
    padding: 0.5rem 0rem;
    color: #aaa;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
  }
  #footer-container #footer-mid-container .footer-mid-box .footer-mid-bottom-two .footer-mid-bottom-box .footer-mid-bottom-elements:hover {
    color: white;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-title {
    margin-top: -2rem;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom {
    height: 10rem;
    width: 90%;
    font-size: 1.1rem;
    color: var(--border-one);
    padding: 1rem 0rem 0 2.5rem;
    box-sizing: border-box;
    cursor: default;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box {
    display: flex;
    position: relative;
    height: 3rem;
    width: 100%;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(1) {
    padding-bottom: 1rem;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(1) .footer-mid-one-right::before {
    top: 0.3rem;
    background-image: url("../../../Images/Footer/location.svg");
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(2) {
    margin-bottom: 0;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(2) .footer-mid-one-right::before {
    background-image: url("../../../Images/Footer/call.svg");
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box:nth-child(3) .footer-mid-one-right::before {
    background-image: url("../../../Images/Footer/mail.svg");
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right {
    font-family: fontLight;
    font-size: 1.1rem;
    position: relative;
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right:hover::before {
    filter: invert(0%);
  }
  #footer-container #footer-mid-container #footer-mid-one .footer-mid-bottom .footer-mid-one-box .footer-mid-one-right::before {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: -2.5rem;
    height: 1.5rem;
    width: 1.5rem;
    background-size: contain;
    background-position: center;
    filter: invert(45%);
    transition: filter 0.3s ease-in-out;
  }
  #footer-container #footer-bottom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
    position: absolute;
    bottom: 0;
    height: 5rem;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 0.95rem;
    color: var(--border-one);
    background-color: var(--footer-two);
    box-sizing: border-box;
  }
  #footer-container #footer-bottom-container a {
    color: var(--border-one);
  }
}
