#message-container {
  display: block;
  align-items: center;
  justify-content: center;
  height: 125vh;
  width: 100%;
  padding: 10rem 7rem 7rem 7rem;
  background-image: url("../../../Images/Waves/get-in-touch.svg");
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}
#message-container #message-title {
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#message-container #message-mid-box {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
#message-container #message-mid-box #message-mid-left {
  position: absolute;
  left: -15rem;
  top: 0;
  height: 100%;
  width: 50%;
  padding: 3rem 0rem;
  opacity: 0;
  box-sizing: border-box;
  animation-name: message-left;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#message-container #message-mid-box #message-mid-left .message-inp-box {
  position: relative;
  padding: 0.3rem 0;
}
#message-container #message-mid-box #message-mid-left .message-inp-box .message-inp-title {
  font-family: fontReg;
  font-size: 1.2rem;
}
#message-container #message-mid-box #message-mid-left .message-inp-box .message-input {
  padding: 0.9rem 0.75rem 0.9rem 1rem;
  width: 100%;
  border-radius: 0.3rem;
  outline: none;
  border: none;
  font-size: 0.9rem;
  font-family: fontLight;
  box-shadow: 3px 3px 10px 0 var(--shadow);
  box-sizing: border-box;
}
#message-container #message-mid-box #message-mid-left .message-inp-box .message-input::placeholder {
  font-size: 0.9rem;
  font-family: fontLight;
}
#message-container #message-mid-box #message-mid-left .message-inp-box input[type=number]::-webkit-inner-spin-button,
#message-container #message-mid-box #message-mid-left .message-inp-box input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
#message-container #message-mid-box #message-mid-left .message-inp-box input[type=number] {
  -moz-appearance: textfield;
}
#message-container #message-mid-box #message-mid-left #message-mid-text-box {
  height: auto;
  width: 100%;
  padding: 1rem 0 0 0;
  font-size: 1.2rem;
  box-sizing: border-box;
}
#message-container #message-mid-box #message-mid-left #message-mid-text-box #message-mid-text {
  height: 17rem;
  width: 100%;
  padding: 1rem;
  font-size: 0.9rem;
  margin-top: 0.3rem;
  font-family: fontLight;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 0.3rem;
  resize: none;
  color: var(--footer-one);
  box-shadow: 3px 3px 10px 0 var(--shadow);
  box-sizing: border-box;
}
#message-container #message-mid-box #message-mid-left #message-mid-text-box #message-mid-text::placeholder {
  font-size: 0.9rem;
  font-family: fontLight;
}
#message-container #message-mid-box #message-mid-left .message-btn {
  position: relative;
  width: fit-content;
  margin: 2rem 0 0.5rem auto;
  padding: 1.25rem 3rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  transition: color 0.5s ease-in-out;
  cursor: pointer;
  box-shadow: 3px 3px 10px 0 #ddd;
}
#message-container #message-mid-box #message-mid-left .message-btn:hover {
  color: white;
}
#message-container #message-mid-box #message-mid-left .message-btn:hover::after {
  width: 100%;
}
#message-container #message-mid-box #message-mid-left .message-btn::before {
  content: "Send";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
#message-container #message-mid-box #message-mid-left .message-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 0.3rem;
  background-color: var(--theme-one);
  transition: width 0.5s ease-in-out;
  z-index: 1;
}
#message-container #message-mid-box #message-mid-left .message-btn-send {
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 2rem 0 0.5rem auto;
  padding: 0.65rem 1rem 0.65rem 1rem;
  border-radius: 0.3rem;
  box-sizing: border-box;
  cursor: pointer;
  background-color: var(--theme-one);
  color: white;
  box-shadow: 3px 3px 10px 0 #ddd;
}
#message-container #message-mid-box #message-mid-left .message-btn-send::after {
  content: "";
  height: 0.5rem;
  width: 0.5rem;
  border: 3px solid;
  border-color: white white var(--footer-one) white;
  margin-left: 0.3rem;
  border-radius: 50%;
  z-index: 1;
  transition: transform 1s ease-in-out;
  animation-name: message-btn-rt;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
@keyframes message-btn-rt {
  100% {
    transform: rotate(360deg);
  }
}
#message-container #message-mid-box #message-mid-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -15rem;
  top: -5rem;
  height: 100%;
  width: 50%;
  opacity: 0;
  animation-name: message-right;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#message-container #message-mid-box #message-mid-right-img {
  height: 35rem;
  animation-name: message-right-img;
  animation-duration: 7s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
@keyframes message-right {
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes message-left {
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes message-right-img {
  50% {
    transform: translateY(1rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@media screen and (max-width: 1025px) {
  #message-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 10rem 2rem 3rem 2rem;
    background-image: url("../../../Images/Waves/get-in-touch.svg");
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
  }
  #message-container #message-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
  }
  #message-container #message-mid-box {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }
  #message-container #message-mid-box #message-mid-left {
    position: relative;
    left: -15rem;
    top: 0;
    order: 2;
    height: auto;
    width: 100%;
    padding: 0rem 0rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: message-left;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #message-container #message-mid-box #message-mid-left .message-inp-box .message-input {
    width: 100%;
    box-sizing: border-box;
    font-size: 1.5rem;
  }
  #message-container #message-mid-box #message-mid-left .message-inp-box .message-input::placeholder {
    font-size: 1.5rem;
  }
  #message-container #message-mid-box #message-mid-left #message-mid-text-box {
    padding: 2rem 0.5rem;
    letter-spacing: 0.5px;
    font-size: 1.5rem;
    box-sizing: border-box;
    color: grey;
  }
  #message-container #message-mid-box #message-mid-left #message-mid-text-box #message-mid-text {
    font-size: 1.5rem;
  }
  #message-container #message-mid-box #message-mid-left #message-mid-text-box #message-mid-text::placeholder {
    font-size: 1.5rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn {
    margin: 0 0.3rem 0 auto;
    padding: 1.75rem 4rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn::before {
    font-size: 1.5rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn-send {
    font-size: 1.5rem;
    margin: 0 0.3rem 0 auto;
    padding: 0.85rem 1.5rem 0.85rem 1.5rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn-send::after {
    height: 0.75rem;
    width: 0.75rem;
  }
  #message-container #message-mid-box #message-mid-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    opacity: 0;
    animation-name: message-right;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #message-container #message-mid-box #message-mid-right-img {
    height: 28rem;
    margin: -3rem 0;
    animation-name: message-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  @keyframes message-right {
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes message-left {
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes message-right-img {
    50% {
      transform: translateY(1rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }
}
@media screen and (max-width: 600px) {
  #message-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 7rem 1rem 3rem 1rem;
    background-image: url("../../../Images/Waves/get-in-touch.svg");
    background-size: cover;
    background-position: center;
    box-sizing: border-box;
  }
  #message-container #message-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
  }
  #message-container #message-mid-box {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
  }
  #message-container #message-mid-box #message-mid-left {
    position: relative;
    left: -15rem;
    top: 0;
    order: 2;
    height: auto;
    width: 100%;
    padding: 0rem 0rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: message-left;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #message-container #message-mid-box #message-mid-left .message-inp-box .message-input {
    width: 100%;
    box-sizing: border-box;
    font-size: 1rem;
  }
  #message-container #message-mid-box #message-mid-left .message-inp-box .message-input::placeholder {
    font-size: 1rem;
  }
  #message-container #message-mid-box #message-mid-left #message-mid-text-box {
    padding: 2rem 0.3rem;
    letter-spacing: 0.5px;
    font-size: 1.2rem;
    box-sizing: border-box;
    color: grey;
  }
  #message-container #message-mid-box #message-mid-left #message-mid-text-box #message-mid-text {
    font-size: 1rem;
  }
  #message-container #message-mid-box #message-mid-left #message-mid-text-box #message-mid-text::placeholder {
    font-size: 1rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn {
    margin: 0 0.3rem 0 auto;
    padding: 1.4rem 3rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn::before {
    font-size: 1rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn-send {
    font-size: 1rem;
    margin: 0 0.3rem 0 auto;
    padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  }
  #message-container #message-mid-box #message-mid-left .message-btn-send::after {
    height: 0.75rem;
    width: 0.75rem;
  }
  #message-container #message-mid-box #message-mid-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    opacity: 0;
    animation-name: message-right;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  #message-container #message-mid-box #message-mid-right-img {
    height: 23rem;
    margin: -1rem 0 -2rem 0;
    animation-name: message-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  @keyframes message-right {
    100% {
      right: 0;
      opacity: 1;
    }
  }
  @keyframes message-left {
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes message-right-img {
    50% {
      transform: translateY(1rem);
    }
    100% {
      transform: translateY(0rem);
    }
  }
}
