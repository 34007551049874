/* fonts */

@font-face {
  font-family: arialLight;
  src: url('./Assets/Fonts/Arial/ARIALLGT.woff');
}

@font-face {
  font-family: arialReg;
  src: url('./Assets/Fonts/Arial/ARIAL.woff');
}


@font-face {
  font-family: fontLight;
  src: url('./Assets/Fonts/Rubik/Rubik-Light.ttf');
}

@font-face {
  font-family: fontReg;
  src: url('./Assets/Fonts/Rubik/Rubik-Regular.ttf');
}

@font-face {
  font-family: fontBold;
  src: url('./Assets/Fonts/Rubik/Rubik-Bold.ttf');
}

@font-face {
  font-family: fontExBold;
  src: url('./Assets/Fonts/Rubik/Rubik-ExtraBold.ttf');
}


:root {
  --theme-one: #1d1c18;
  --theme-two: #e919fb;
  --theme-three: #49375e;
  --theme-four: #66b0f0;
  --theme-five: #f84f46;
  --theme-six: #ffcf2d;
  --bg: #fbfbfb;

  --shadow: rgb(229, 239, 240);
  --shadow-two: rgb(212, 225, 226);

  --border-one: #b3b3b3;
  --footer-one: #2e3033;
  --footer-two: #151515;
}



body {
  margin: 0;
  font-family: fontLight;
  letter-spacing: -1px;
  scroll-behavior: smooth;  
}

h1,h2,h3,h4,h5,button {
  font-family: fontBold;
}

::placeholder {
  font-family: fontReg;
}

::-webkit-scrollbar {
  display: block;
  background-color: transparent;
} 
::-webkit-scrollbar-track {
  border-radius: 0rem;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: var(--shadow-two);
  border-radius: 0.2rem;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background-color: var(--footer-one);
}

@media screen and (max-width: 1000px) {
  ::-webkit-scrollbar {
    display: none;
  }
}