
#home-container {
  height: auto;
  width: 100%;
  padding-top: 0rem;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
#home-container::-webkit-scrollbar {
  display: none;
}
#home-container #home-one-container {
  display: flex;
  position: relative;
  height: 100vh;
  width: 100%;
  padding-left: 9.5rem;
  /* padding-top: 53rem; */
  padding-top: 25rem;
  padding-bottom: 5rem;
  background-image: url('../../../Images/Dizital/bgOne.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
#home-container #home-one-container #home-one-left-box {
  height: 15rem;
  width: auto;
  z-index: 10;
}
#home-container #home-one-container #home-one-left-box #home-one-left-top {
  display: flex;
  align-items: center;
  height: 35%;
  width: 100%;
  font-family: fontReg;
  font-weight: bolder;
  font-size: 4rem;
  color: var(--theme-one);
}
#home-container #home-one-container #home-one-left-box #home-one-left-bottom {
  display: flex;
  align-items: center;
  height: 25%;
  width: 100%;
  font-size: 1.2rem;
  color: #ddd;
}
#home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: auto;
}
#home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn {
  position: relative;
  padding: 0.75rem 2rem;
  border-radius: 1.5rem;
  margin-top: 1rem;
  margin-right: 1rem;
  /* box-shadow: 3px 3px 15px 0 var(--shadow); */
  /* border: 2px solid var(--theme-three); */
  border: 2px solid #fff;
  font-weight: 600;
  font-size: 1.1rem;
  /* color: var(--theme-three); */
  color: #fff;
  transition: border-color 0.5 ease-in-out, color 0.5s ease-in-out, background-color 0.5s ease-in-out;
  cursor: pointer;
}
#home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn a {
  text-decoration: none;
  color: #fff;
  font-weight: 100;
  transition: color 0s ease-in-out;
}
#home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn:hover {
  color: white;
  font-weight: 100;
  background-color: var(--theme-one);
  border-color: var(--theme-one);
}
#home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn:hover a {
  color: white;
  font-weight: 100;
}
#home-container #home-one-container #home-one-left-box #home-one-left-mid {
  display: flex;
  align-items: center;
  position: relative;
  height: fit-content;
  font-family: fontReg;
  font-weight: bolder;
  width: fit-content;
  font-size: 4rem;
  color: var(--theme-two);
  padding-right: 1rem;
  overflow-x: hidden;
}
#home-container #home-one-container #home-one-left-box #home-one-left-mid .home-one-left-mid-text {
  height: 5rem;
  width: 0;
  padding-right: 0.5rem;
  overflow: hidden;
  animation-name: text-cursor;
  animation-duration: 4s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  border-right: 4px solid var(--theme-two);
}
@keyframes text-cursor {
  0% {
    width: 0;
 }
  40% {
    width: calc(100% - 0.5rem);
 }
  80% {
    width: calc(100% - 0.5rem);
 }
  100% {
    width: 0%;
 }
}
#home-container #home-one-container #home-one-anime-one {
  position: absolute;
  top: 40rem;
  left: 64rem;
  height: 35rem;
  width: 35rem;
  background-image: url('../../../Images/Sammy/one.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: one-anime-one;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
@keyframes one-anime-one {
  0% {
    transform: translateY(0);
 }
  50% {
    transform: translateY(2rem);
 }
  100% {
    transform: translateY(0);
 }
}
#home-container #home-one-container #home-one-anime-two {
  position: absolute;
  bottom: -6rem;
  left: 30rem;
  height: 30rem;
  width: 30rem;
  background-image: url('../../../Images/Sammy/five.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation-name: one-anime-two;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
@keyframes one-anime-two {
  0% {
    transform: translateX(0);
 }
  50% {
    transform: translateX(5rem);
 }
  100% {
    transform: translateX(0);
 }
}
#home-container #home-two-container {
  height: 80vh;
  width: 100%;
  background-image: url('../../../Images/Dizital/bgEight.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-two-container #home-two-top-box {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 8rem;
  width: 100%;
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#home-container #home-two-container #home-two-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 5rem 13rem;
  height: 25rem;
  width: 73.5%;
}
#home-container #home-two-container #home-two-box:hover .home-two-btn {
  opacity: 1;
}
#home-container #home-two-container #home-two-box .home-two-btn {
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--theme-one);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
}
#home-container #home-two-container #home-two-box .home-two-btn:hover {
  background-color: #1d1c18 cc;
}
#home-container #home-two-container #home-two-box .home-two-btn:first-child {
  margin-left: -1.5rem;
}
#home-container #home-two-container #home-two-box .home-two-btn:last-child {
  transform: rotate(180deg);
  margin-right: -1.5rem;
}
#home-container #home-two-container #home-two-box #home-two-box-mid {
  display: flex;
  align-items: center;
  position: absolute;
  left: 10rem;
  height: 100%;
  width: 100%;
  padding: 1rem 0;
  scroll-behavior: smooth;
  overflow-x: scroll;
  animation-name: home-two-box-mid;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-two-container #home-two-box #home-two-box-mid::-webkit-scrollbar {
  display: block;
}
#home-container #home-two-container #home-two-box #home-two-box-mid::-webkit-scrollbar-track {
  border-radius: 0.5rem;
}
#home-container #home-two-container #home-two-box #home-two-box-mid::-webkit-scrollbar-thumb {
  background-color: var(--shadow);
  border-radius: 0.5rem;
}
#home-container #home-two-container #home-two-box #home-two-box-mid::-webkit-scrollbar-track-piece {
  border-radius: 0.5rem;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi {
  display: block;
  align-items: center;
  flex-flow: column;
  justify-content: flex-start;
  position: relative;
  height: 100%;
  width: 20rem;
  padding: 2rem 1.5rem 1rem 1.5rem;
  margin: 0 1rem;
  text-align: center;
  border-radius: 0.7rem;
  background-color: white;
  box-shadow: 3px 3px 25px 0 var(--shadow);
  box-sizing: border-box;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(1) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/nine.png');  
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(2) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/six.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(3) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/seven.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(4) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/eight.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(5) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/seven.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(6) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/five.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(7) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/six.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(8) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/seven.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(9) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/eight.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(10) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/six.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(11) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/seven.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(12) .home-two-box-indi-icon {
  background-image: url('../../../Images/Sammy/eight.png');
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
  padding: 0.3rem 0;
  font-size: 1.5rem;
  font-family: fontReg;
  font-weight: 0;
  color: var(--theme-one);
  box-sizing: border-box;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-subtitle {
  height: auto;
  width: 15rem;
  padding: 0.3rem 0;
  font-size: 1.1rem;
  color: grey;
  box-sizing: border-box;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-btn {
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  width: fit-content;
  padding: 0.2rem 0.5rem;
  font-size: 0.95rem;
  border-radius: 0.3rem;
  border: 1px solid grey;
  box-sizing: border-box;
  transition: color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  cursor: pointer;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-btn:hover {
  color: white;
  border-color: var(--theme-one);
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-btn:hover::after {
  width: 101%;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-btn::before {
  content: 'Read More';
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
#home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 0;
  border-radius: 0.3rem;
  background-color: var(--theme-one);
  transition: width 0.5s ease-in-out;
  z-index: 1;
}
@keyframes home-two-box-mid {
  100% {
    left: 0;
 }
}
#home-container #home-three-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  width: 100%;
  background-image: url('../../../Images/Dizital/bgNine.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-three-container #home-three-mid {
  position: relative;
  height: 80%;
  width: 90%;
}
#home-container #home-three-container #home-three-mid #home-three-mid-left {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -20rem;
  top: 0;
  height: 100%;
  width: 50%;
  padding-top: 6rem;
  opacity: 0;
  animation-name: home-three-left;
  animation-duration: 1s;
  animation-fill-mode: both;
  box-sizing: border-box;
}
#home-container #home-three-container #home-three-mid #home-three-mid-left-img {
  height: 35rem;
  animation-name: home-three-left-img;
  animation-duration: 7s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
#home-container #home-three-container #home-three-mid #home-three-mid-right {
  position: absolute;
  right: -20rem;
  top: 0;
  height: 100%;
  width: 50%;
  padding: 4rem 1rem;
  opacity: 0;
  box-sizing: border-box;
  animation-name: home-three-right;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-title {
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-subtitle {
  padding: 2rem 0;
  font-size: 1.2rem;
  color: grey;
  letter-spacing: 0.5px;
  line-height: 1.7rem;
  box-sizing: border-box;
  text-align: justify;
}
#home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn {
  position: relative;
  width: fit-content;
  padding: 1rem 3rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 var(--shadow-two);
  transition: color 0.5s ease-in-out;
  cursor: pointer;
}
#home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn:hover {
  color: white;
}
#home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn:hover::after {
  width: 100%;
}
#home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  z-index: -2;
  background-color: white;
}
#home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 0.5rem;
  background-color: var(--theme-one);
  transition: width 0.5s ease-in-out;
  z-index: -1;
}
@keyframes home-three-left {
  100% {
    left: 0;
    opacity: 1;
 }
}
@keyframes home-three-right {
  100% {
    right: 0;
    opacity: 1;
 }
}
@keyframes home-three-left-img {
  50% {
    transform: translateY(1rem);
 }
  100% {
    transform: translateY(0rem);
 }
}
#home-container #home-four-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100%;
  background-image: url('../../../Images/Dizital/bgFive.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-four-container #home-four-mid {
  position: relative;
  height: 80%;
  width: 90%;
}
#home-container #home-four-container #home-four-mid #home-four-mid-left {
  position: absolute;
  left: -15rem;
  top: 0;
  height: 100%;
  width: 50%;
  padding: 4rem 5rem;
  opacity: 0;
  box-sizing: border-box;
  animation-name: home-four-left;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-four-container #home-four-mid #home-four-mid-left #home-four-mid-title {
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#home-container #home-four-container #home-four-mid #home-four-mid-left #home-four-mid-subtitle {
  padding: 2rem 0;
  color: grey;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  line-height: 1.7rem;
  box-sizing: border-box;
  text-align: justify;
}
#home-container #home-four-container #home-four-mid #home-four-mid-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -15rem;
  top: 0;
  height: 100%;
  width: 50%;
  opacity: 0;
  animation-name: home-four-right;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-four-container #home-four-mid #home-four-mid-right-img {
  height: 35rem;
  animation-name: home-four-right-img;
  animation-duration: 7s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
@keyframes home-four-right {
  100% {
    right: 0;
    opacity: 1;
 }
}
@keyframes home-four-left {
  100% {
    left: 0;
    opacity: 1;
 }
}
@keyframes home-four-right-img {
  50% {
    transform: translateY(1rem);
 }
  100% {
    transform: translateY(0rem);
 }
}
#home-container #home-five-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  height: 80vh;
  width: 100%;
  box-sizing: border-box;
  background-image: url('../../../Images/Dizital/bgTwelve.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-five-container #home-five-top-box {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 8rem;
  width: 100%;
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#home-container #home-five-container #home-five-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 30rem;
  width: 95%;
  padding-bottom: 3rem;
  box-sizing: border-box;
  overflow-x: scroll;
}
#home-container #home-five-container #home-five-box::-webkit-scrollbar {
  display: none;
}
#home-container #home-five-container #home-five-box:hover .home-five-btn {
  opacity: 1;
}
#home-container #home-five-container #home-five-box .home-five-btn {
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--theme-one);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  cursor: pointer;
}
#home-container #home-five-container #home-five-box .home-five-btn:hover {
  background-color: #1d1c18 cc;
}
#home-container #home-five-container #home-five-box .home-five-btn:first-child {
  margin-left: -1.5rem;
}
#home-container #home-five-container #home-five-box .home-five-btn:last-child {
  transform: rotate(180deg);
  margin-right: -1.5rem;
}
#home-container #home-five-container #home-five-box #home-five-box-mid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row nowrap;
  position: absolute;
  left: 20rem;
  height: 65%;
  padding: 1rem 0;
  scroll-behavior: smooth;
  overflow-x: scroll;
  animation-name: home-five-box-mid;
  animation-duration: 2s;
  animation-fill-mode: both;
}
#home-container #home-five-container #home-five-box #home-five-box-mid::-webkit-scrollbar {
  display: none;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi {
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: flex-start;
  height: 15rem;
  width: 22.5rem;
  padding: 2rem 3rem;
  margin: 0 1rem;
  text-align: left;
  border-radius: 0.7rem;
  background-color: white;
  box-shadow: 3px 3px 10px 0 #ddd;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top {
  display: flex;
  align-items: center;
  height: 3rem;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top .home-five-box-indi-top-right {
  font-size: 1.1rem;
  padding-left: 1rem;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-mid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 90%;
  padding: 1.5rem 0;
  font-size: 1.1rem;
  color: var(--theme-one);
  box-sizing: border-box;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom {
  display: flex;
  flex-flow: row wrap;
  height: 4rem;
  width: 100%;
  box-sizing: border-box;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-left {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: fit-content;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-left .home-five-box-indi-bottom-left-icon {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right {
  margin: 0.5rem 0 0 2rem;
  height: 4rem;
  width: fit-content;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right .home-five-box-indi-bottom-right-title {
  font-size: 1.5rem;
  font-family: fontReg;
  color: var(--theme-three);
  width: 100%;
}
#home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right .home-five-box-indi-bottom-right-subtitle {
  font-size: 1.1rem;
  width: 100%;
  cursor: pointer;
  color: grey;
  font-family: fontLight;
}
@keyframes home-five-box-mid {
  100% {
    left: 0;
 }
}
#home-container #home-six-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url('../../../Images/Dizital/bgEleven.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-six-container #home-six-title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 8rem;
  width: 100%;
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#home-container #home-six-container #home-six-box {
  position: relative;
  height: 25rem;
  width: 100%;
  margin-top: -3rem;
}
#home-container #home-six-container #home-six-box #home-six-box-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: -20rem;
  opacity: 0;
  height: 48%;
  width: auto;
  animation-name: home-six-box-one;
  animation-duration: 3s;
  animation-fill-mode: both;
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements {
  position: relative;
  height: 50%;
  width: 7.6rem;
  margin: 0 1rem;
  border-radius: 2rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(1) {
  background-image: url('../../../Images/Company/cTwo.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(2) {
  background-image: url('../../../Images/Company/cThree.jpg');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(3) {
  background-image: url('../../../Images/Company/cFour.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(4) {
  background-image: url('../../../Images/Company/cFive.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(5) {
  background-image: url('../../../Images/Company/cSix.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(6) {
  background-image: url('../../../Images/Company/cSeven.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(7) {
  background-image: url('../../../Images/Company/cEight.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(8) {
  background-image: url('../../../Images/Company/cNine.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(9) {
  background-image: url('../../../Images/Company/cTen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(10) {
  background-image: url('../../../Images/Company/cEleven.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(11) {
  background-image: url('../../../Images/Company/cSix.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(12) {
  background-image: url('../../../Images/Company/cFour.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(13) {
  background-image: url('../../../Images/Company/cEight.png');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(14) {
  background-image: url('../../../Images/Company/cThree.jpg');
}
#home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(15) {
  background-image: url('../../../Images/Company/cTwo.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  right: -20rem;
  opacity: 0;
  height: 48%;
  width: auto;
  animation-name: home-six-box-two;
  animation-duration: 3s;
  animation-fill-mode: both;
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements {
  position: relative;
  height: 50%;
  width: 7.6rem;
  margin: 0 1rem;
  border-radius: 2rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(1) {
  background-image: url('../../../Images/Company/cTwelve.jpg');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(2) {
  background-image: url('../../../Images/Company/cThirteen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(3) {
  background-image: url('../../../Images/Company/cFourteen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(4) {
  background-image: url('../../../Images/Company/cFifteen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(5) {
  background-image: url('../../../Images/Company/cSixteen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(6) {
  background-image: url('../../../Images/Company/cSeventeen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(7) {
  background-image: url('../../../Images/Company/cEighteen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(8) {
  background-image: url('../../../Images/Company/cNinteen.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(9) {
  background-image: url('../../../Images/Company/cTwenty.png');
}
#home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(10) {
  background-image: url('../../../Images/Company/cOne.png');
}
@keyframes home-six-box-one {
  100% {
    opacity: 1;
    left: 0;
 }
}
@keyframes home-six-box-two {
  100% {
    opacity: 1;
    right: 0;
 }
}
#home-container #home-six-container #home-six-btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-top: -2rem;
}
#home-container #home-six-container #home-six-btn-box #home-six-btn {
  position: relative;
  width: fit-content;
  padding: 1rem 3rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 var(--shadow-two);
  transition: color 0.5s ease-in-out;
  cursor: pointer;
}
#home-container #home-six-container #home-six-btn-box #home-six-btn:hover {
  color: white;
}
#home-container #home-six-container #home-six-btn-box #home-six-btn:hover::after {
  width: 100%;
}
#home-container #home-six-container #home-six-btn-box #home-six-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  z-index: -2;
  background-color: white;
}
#home-container #home-six-container #home-six-btn-box #home-six-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 0.5rem;
  background-color: var(--theme-one);
  transition: width 0.5s ease-in-out;
  z-index: -1;
}
#home-container #home-seven-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 6rem);
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-seven-container #home-seven-mid {
  position: relative;
  height: 80%;
  width: 90%;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -20rem;
  top: 0;
  height: 100%;
  width: 50%;
  padding-top: 3rem;
  padding-left: 5rem;
  opacity: 0;
  animation-name: home-seven-left;
  animation-duration: 1s;
  animation-fill-mode: both;
  box-sizing: border-box;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box {
  display: flex;
  height: 32rem;
  width: 45rem;
  box-sizing: border-box;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element {
  position: relative;
  height: 100%;
  width: 50%;
  box-sizing: border-box;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(1) .home-seven-mid-left-box-element-square:nth-child(1) .home-seven-left-top .home-seven-left-top-one {
  color: var(--theme-four);
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(1) .home-seven-mid-left-box-element-square:nth-child(2) .home-seven-left-top .home-seven-left-top-one {
  color: var(--theme-five);
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) {
  margin-top: 2rem;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) .home-seven-mid-left-box-element-square:nth-child(1) .home-seven-left-top .home-seven-left-top-one {
  color: var(--theme-six);
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) .home-seven-mid-left-box-element-square:nth-child(2) .home-seven-left-top .home-seven-left-top-one {
  color: var(--theme-two);
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square {
  height: 43%;
  width: 90%;
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: white;
  box-shadow: 1px 1px 25px 0 var(--shadow);
  box-sizing: border-box;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover {
  background-color: var(--footer-one);
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover .home-seven-left-top .home-seven-left-top-two {
  font-weight: 100;
  color: white;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover .home-seven-left-bottom {
  color: white;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top {
  display: flex;
  height: 5rem;
  width: 100%;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top .home-seven-left-top-one {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-family: fontReg;
  font-size: 2.75rem;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top .home-seven-left-top-two {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-left: 1.5rem;
  font-family: fontReg;
  font-size: 1.3rem;
  color: var(--theme-three);
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-bottom {
  padding-top: 1rem;
  font-size: 1.1rem;
  letter-spacing: 0.3px;
  color: grey;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right {
  position: absolute;
  right: -20rem;
  top: 0;
  height: 100%;
  width: 50%;
  padding: 4rem 1rem;
  opacity: 0;
  box-sizing: border-box;
  animation-name: home-seven-right;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-title {
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-subtitle {
  padding: 2rem 0;
  font-size: 1.2rem;
  box-sizing: border-box;
  color: grey;
  letter-spacing: 0.5px;
  line-height: 1.7rem;
  text-align: justify;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn {
  position: relative;
  width: fit-content;
  padding: 1rem 3rem;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px 0 var(--shadow-two);
  transition: color 0.5s ease-in-out;
  cursor: pointer;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn:hover {
  color: white;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn:hover::after {
  width: 100%;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0.5rem;
  z-index: -2;
  background-color: white;
}
#home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  border-radius: 0.5rem;
  background-color: var(--theme-one);
  transition: width 0.5s ease-in-out;
  z-index: -1;
}
@keyframes home-seven-left {
  100% {
    left: 0;
    opacity: 1;
 }
}
@keyframes home-seven-right {
  100% {
    right: 0;
    opacity: 1;
 }
}
@keyframes home-seven-left-img {
  50% {
    transform: translateY(1rem);
 }
  100% {
    transform: translateY(0rem);
 }
}
#home-container #home-eight-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100%;
  background-image: url('../../../Images/Dizital/bgTen.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-eight-container #home-eight-mid {
  position: relative;
  height: 80%;
  width: 90%;
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left {
  position: absolute;
  left: -15rem;
  top: 0;
  height: 100%;
  width: 60%;
  padding: 0rem 5rem;
  opacity: 0;
  box-sizing: border-box;
  animation-name: home-eight-left;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-title {
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down {
  height: 30rem;
  padding: 2rem 0;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  box-sizing: border-box;
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin: 1rem 0;
  padding: 0.2rem;
  background-color: white;
  transition: height 0.5s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 3px 3px 15px 0 var(--shadow);
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont:hover {
  box-shadow: 3px 3px 15px 0 var(--shadow-two);
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-box-title {
  display: flex;
  align-items: center;
  height: 3rem;
  width: 75%;
  font-size: 1.05rem;
  font-family: fontReg;
  padding: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  height: 3rem;
  width: 10%;
  min-width: 5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-mid-drop-img {
  padding: 0.4rem;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-rotate-down {
  transition: transform 0.3s ease-in-out;
  transform: rotate(180deg);
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-rotate-up {
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg);
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-content {
  width: 100%;
  padding: 1rem;
  margin-top: -5%;
  font-size: 1rem;
  color: grey;
  animation-name: mountPop;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
@keyframes mountPop {
  0% {
    margin-top: -5%;
    opacity: 0.5;
 }
  100% {
    margin-top: 0;
    opacity: 1;
 }
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-right {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -15rem;
  top: 0;
  height: 100%;
  width: 40%;
  opacity: 0;
  animation-name: home-eight-right;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-eight-container #home-eight-mid #home-eight-mid-right-img {
  height: 35rem;
  margin-top: -5rem;
  animation-name: home-eight-right-img;
  animation-duration: 7s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
@keyframes home-eight-right {
  100% {
    right: 0;
    opacity: 1;
 }
}
@keyframes home-eight-left {
  100% {
    left: 0;
    opacity: 1;
 }
}
@keyframes home-eight-right-img {
  50% {
    transform: translateY(1rem);
 }
  100% {
    transform: translateY(0rem);
 }
}
#home-container #home-nine-container {
  height: 75vh;
  width: 100%;
  padding: 0 10rem;
  box-sizing: border-box;
  background-image: url('../../../Images/Waves/products.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-nine-container #home-nine-title {
  width: 100%;
  padding: 5rem 0 2rem 0;
  font-family: fontReg;
  font-weight: bolder;
  font-size: 3rem;
  color: var(--theme-one);
  box-sizing: border-box;
}
#home-container #home-nine-container #home-nine-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 3rem auto;
  height: 25rem;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
#home-container #home-nine-container #home-nine-box::-webkit-scrollbar {
  display: block;
}
#home-container #home-nine-container #home-nine-box::-webkit-scrollbar-track {
  border-radius: 0.5rem;
}
#home-container #home-nine-container #home-nine-box::-webkit-scrollbar-thumb {
  background-color: var(--shadow);
  border-radius: 0.5rem;
}
#home-container #home-nine-container #home-nine-box::-webkit-scrollbar-track-piece {
  border-radius: 0.5rem;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  position: absolute;
  left: 0;
  height: auto;
  width: auto;
  padding: 1rem 0;
  scroll-behavior: smooth;
  overflow-x: scroll;
  animation-name: home-nine-bottom;
  animation-duration: 1s;
  animation-fill-mode: both;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi {
  display: block;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
  height: 22rem;
  width: 18rem;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem 1rem 0 1rem;
  box-shadow: 5px 5px 15px 0 #ddd;
  background-color: white;
  box-sizing: border-box;
  cursor: default;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi:nth-child(1) .home-nine-bottom-indi-icon {
  background-image: url('../../../Images/Sammy/nine.png');
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi:nth-child(2) .home-nine-bottom-indi-icon {
  background-image: url('../../../Images/Sammy/six.png');
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi:nth-child(3) .home-nine-bottom-indi-icon {
  background-image: url('../../../Images/Sammy/seven.png');
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi:nth-child(4) .home-nine-bottom-indi-icon {
  background-image: url('../../../Images/Sammy/eight.png');
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi:nth-child(5) .home-nine-bottom-indi-icon {
  background-image: url('../../../Images/Sammy/seven.png');
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi:nth-child(6) .home-nine-bottom-indi-icon {
  background-image: url('../../../Images/Sammy/five.png');
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi:nth-child(7) .home-nine-bottom-indi-icon {
  background-image: url('../../../Images/Sammy/six.png');
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-icon {
  height: 8rem;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-title {
  height: auto;
  width: 100%;
  padding: 1rem 0 0 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bolder;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-subtitle {
  height: auto;
  width: 100%;
  padding-top: 0.5rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  text-align: center;
  color: grey;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn {
  position: absolute;
  width: fit-content;
  bottom: 0.75rem;
  right: 1rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 0.95rem;
  border: 1px solid grey;
  box-sizing: border-box;
  transition: color 0.5s ease-in-out, border-color 0.5s ease-in-out;
  cursor: pointer;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn:hover {
  color: white;
  border-color: var(--theme-one);
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn:hover::after {
  width: 101%;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn::before {
  content: 'Read More';
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}
#home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 0;
  border-radius: 0.2rem;
  background-color: var(--theme-one);
  transition: width 0.5s ease-in-out;
  z-index: 1;
}
@keyframes home-nine-bottom {
  100% {
    left: 0;
 }
}
#home-container #home-ten-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  padding: 5rem 10rem 2rem 10rem;
  box-sizing: border-box;
  background-image: url('../../../Images/Dizital/bgTwenty.svg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  overflow: hidden;
}
#home-container #home-ten-container .home-ten-indi {
  height: 28rem;
  width: 22rem;
  padding: 2rem 2rem 1rem 2rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 20px 0 #eee;
  box-sizing: border-box;
  background-color: #fff;
  cursor: default;
  transition: transform 2s ease-in-out;
}
#home-container #home-ten-container .home-ten-indi .home-ten-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 12rem;
  width: 15rem;
  margin: 0 auto;
}
#home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 11rem;
  width: auto;
  border-radius: 50%;
  background-image: url('../../../Images/Sammy/twelve.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  height: 13rem;
  width: 13rem;
  border: 2.5px solid;
  border-color: #ddd transparent #ddd #ddd;
  transition: transform 1s ease-in-out;
}
#home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon::after {
  content: '';
  border-radius: 50%;
  height: 14rem;
  width: 14rem;
  border: 2.5px solid;
  border-color: #ddd #ddd #ddd transparent;
  transition: transform 1s ease-in-out;
}
#home-container #home-ten-container .home-ten-indi:nth-child(1) {
  transform: translateX(30rem);
}
#home-container #home-ten-container .home-ten-indi:nth-child(2) {
  transform: scale(0);
}
#home-container #home-ten-container .home-ten-indi:nth-child(2) .home-ten-icon-box .home-ten-icon {
  background-image: url('../../../Images/Sammy/thirteen.png');
}
#home-container #home-ten-container .home-ten-indi:nth-child(3) {
  transform: translateX(-30rem);
}
#home-container #home-ten-container .home-ten-indi:nth-child(3) .home-ten-icon-box .home-ten-icon {
  background-image: url('../../../Images/Sammy/fourteen.png');
}
#home-container #home-ten-container .home-ten-indi .home-ten-title {
  width: fit-content;
  margin: 2rem auto 0.5rem auto;
  font-family: fontReg;
  font-size: 1.75rem;
}
#home-container #home-ten-container .home-ten-indi .home-ten-subtitle {
  font-size: 1.1rem;
  color: grey;
  letter-spacing: 0.3px;
  line-height: 1.3rem;
  text-align: center;
}
#home-container #home-ten-container .home-ten-indi:hover .home-ten-icon-box .home-ten-icon::before {
  transform: rotate(450deg);
}
#home-container #home-ten-container .home-ten-indi:hover .home-ten-icon-box .home-ten-icon::after {
  transform: rotate(270deg);
}
#home-container #home-ten-container .home-ten-indi-anime:nth-child(1) {
  transform: translateX(0rem);
}
#home-container #home-ten-container .home-ten-indi-anime:nth-child(2) {
  transform: scale(1);
}
#home-container #home-ten-container .home-ten-indi-anime:nth-child(3) {
  transform: translateX(0rem);
}
@media screen and (max-width: 1025px) {
  #home-container {
    height: auto;
    width: 100%;
    padding-top: 0rem;
    overflow-x: hidden;
 }
  #home-container #home-one-container {
    display: block;
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 0 2rem;
    padding-top: 25rem;
    padding-bottom: 5rem;
    background-image: url('../../../Images/Dizital/bgOne.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-one-container #home-one-left-box {
    height: 10rem;
    width: 100%;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-top {
    display: flex;
    align-items: center;
    height: 35%;
    width: 100%;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.6rem;
    color: var(--theme-one);
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom {
    display: flex;
    align-items: center;
    height: 25%;
    width: 100%;
    margin-top: 0rem;
    font-size: 1.6rem;
    color: #fff;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    height: auto;
    margin-top: -1.5rem;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn {
    position: relative;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    margin-top: 0rem;
    margin-right: 0.5rem;
    /* box-shadow: 3px 3px 15px 0 var(--shadow); */
    border: 2px solid var(--theme-two);
    font-weight: 100;
    font-size: 1.6rem;
    color: #fff;
    transition: border-color 0.5 ease-in-out, background-color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn a {
    text-decoration: none;
    color: #fff;
    font-weight: 100;
    transition: color 0s ease-in-out;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn:hover {
    color: white;
    font-weight: 100;
    background-color: var(--theme-one);
    border-color: var(--theme-one);
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn:hover a {
    color: white;
    font-weight: 100;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-mid {
    display: flex;
    align-items: center;
    position: relative;
    height: fit-content;
    font-family: fontReg;
    font-weight: bolder;
    width: fit-content;
    font-size: 2.5rem;
    color: var(--theme-two);
    padding-right: 1rem;
    margin: 1rem 0;
    overflow-x: hidden;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-mid .home-one-left-mid-text {
    height: 3rem;
  }

  #home-container #home-one-container #home-one-anime-one {
    display: block;
    position: relative;
    top: 5rem;
    left: 0rem;
    height: 50rem;
    width: 45rem;
    background-image: url('../../../Images/Sammy/one.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation-name: one-anime-one;
    animation-duration: 8s;
    animation-iteration-count: infinite;
 }
  @keyframes one-anime-one {
    0% {
      transform: translateY(0);
   }
    50% {
      transform: translateY(2rem);
   }
    100% {
      transform: translateY(0);
   }
 }
  #home-container #home-one-container #home-one-anime-two {
    display: none;
 }
  #home-container #home-two-container {
    height: auto;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgEight.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-two-container #home-two-top-box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 8rem;
    width: 100%;
    padding-left: 0rem;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
 }
  #home-container #home-two-container #home-two-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 5rem 1.75rem 5rem 2rem;
    height: 40rem;
    width: 91%;
    box-sizing: border-box;
    overflow-x: scroll;
 }
  #home-container #home-two-container #home-two-box::-webkit-scrollbar {
    display: block;
 }
  #home-container #home-two-container #home-two-box::-webkit-scrollbar-track {
    border-radius: 0.5rem;
 }
  #home-container #home-two-container #home-two-box::-webkit-scrollbar-thumb {
    background-color: var(--shadow);
    border-radius: 0.5rem;
 }
  #home-container #home-two-container #home-two-box::-webkit-scrollbar-track-piece {
    border-radius: 0.5rem;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10rem;
    height: auto;
    width: auto;
    padding: 1rem 0;
    scroll-behavior: smooth;
    overflow-x: scroll;
    animation-name: home-two-box-mid;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid::-webkit-scrollbar {
    display: block;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi {
    display: block;
    align-items: center;
    flex-flow: column;
    justify-content: flex-start;
    height: 35rem;
    width: 30rem;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    margin: 0 0.5rem;
    text-align: center;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 3px 3px 25px 0 var(--shadow);
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(1) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/nine.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(2) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/six.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(3) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/seven.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(4) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/eight.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(5) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/seven.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(6) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/five.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(7) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/six.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(8) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/seven.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(9) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/eight.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13rem;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 0.75rem 0;
    font-size: 2.2rem;
    font-family: fontReg;
    font-weight: 0;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-subtitle {
    height: auto;
    width: 25rem;
    margin: 0 auto;
    font-size: 1.7rem;
    color: grey;
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-btn {
    font-size: 1.5rem;
    bottom: 1.5rem;
    padding: 0.2rem 0.5rem;
 }
  @keyframes home-two-box-mid {
    100% {
      left: 0;
   }
 }
  #home-container #home-three-container {
    display: block;
    align-items: flex-start;
    justify-content: center;
    height: 50rem;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-three-container #home-three-mid {
    position: relative;
    height: 100%;
    width: 100%;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right {
    position: block;
    left: -20rem;
    top: 0;
    height: auto;
    width: 100%;
    padding: 4rem 2rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-three-right;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-subtitle {
    padding: 2rem 0;
    font-size: 1.75rem;
    box-sizing: border-box;
    color: grey;
    height: fit-content;
    letter-spacing: 0.75px;
    line-height: 2.5rem;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn {
    position: relative;
    width: fit-content;
    padding: 1rem 3rem;
    font-size: 1.7rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 var(--shadow-two);
    transition: color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn:hover {
    color: white;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn:hover::after {
    width: 100%;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -2;
    background-color: white;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 0.5rem;
    background-color: var(--theme-one);
    transition: width 0.5s ease-in-out;
    z-index: -1;
 }
  @keyframes home-three-right {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-three-left-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-four-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 5rem 0 0rem 0;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgFive.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-four-container #home-four-mid {
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-left {
    position: relative;
    height: auto;
    width: 100%;
    padding: 0rem 2rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-four-left;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-left #home-four-mid-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-left #home-four-mid-subtitle {
    padding: 2rem 0;
    letter-spacing: 0.75px;
    font-size: 1.75rem;
    line-height: 2.5rem;
    box-sizing: border-box;
    color: grey;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-right {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -15rem;
    top: 0;
    height: 100%;
    width: 50%;
    opacity: 0;
    animation-name: home-four-right;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-right-img {
    height: 35rem;
    animation-name: home-four-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
 }
  @keyframes home-four-right {
    100% {
      right: 0;
      opacity: 1;
   }
 }
  @keyframes home-four-left {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-four-right-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-five-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: row wrap;
    height: auto;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-top-box {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 8rem;
    width: 100%;
    padding: 0 2rem 1rem 2rem;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 37rem;
    width: 90%;
    padding: 2rem 0 5rem 0;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    position: absolute;
    left: 0;
    height: 100%;
    width: auto;
    padding: 1rem 0rem;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;
    animation-name: home-five-box-mid;
    animation-duration: 2s;
    animation-fill-mode: both;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi {
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    justify-content: flex-start;
    position: relative;
    height: auto;
    min-height: 28rem;
    width: 25rem;
    padding: 1rem 1.5rem;
    margin: 0 1rem;
    text-align: left;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 1px 1px 15px 0 var(--shadow);
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top {
    display: flex;
    align-items: center;
    height: 3rem;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top .home-five-box-indi-top-left {
    width: auto;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top .home-five-box-indi-top-left .home-five-box-indi-left-icon {
    height: 2.3rem;
    width: 2.3rem;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top .home-five-box-indi-top-right {
    font-size: 1.6rem;
    padding-left: 1rem;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 90%;
    padding: 1.5rem 0;
    font-size: 1.6rem;
    letter-spacing: 0.75px;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    position: absolute;
    bottom: 1.2rem;
    left: 1.5rem;
    height: auto;
    width: fit-content;
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    width: fit-content;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-left .home-five-box-indi-bottom-left-icon {
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right {
    margin: 0rem 0 0 1rem;
    height: 4rem;
    width: fit-content;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right .home-five-box-indi-bottom-right-title {
    font-size: 2rem;
    font-family: fontReg;
    color: var(--theme-three);
    width: 100%;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right .home-five-box-indi-bottom-right-subtitle {
    font-size: 1.5rem;
    width: 100%;
    cursor: pointer;
    color: grey;
    font-family: fontLight;
 }
  @keyframes home-five-box-mid {
    100% {
      left: 0;
   }
 }
  #home-container #home-six-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: row wrap;
    position: relative;
    height: 55vh;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgNine.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-six-container #home-six-title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 5rem;
    width: 100%;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    padding: 0 1.5rem;
    color: var(--theme-one);
 }
  #home-container #home-six-container #home-six-box {
    position: relative;
    height: 15rem;
    width: 100%;
    margin-top: -3rem;
 }
  #home-container #home-six-container #home-six-box #home-six-box-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: -20rem;
    opacity: 0;
    height: 48%;
    width: auto;
    animation-name: home-six-box-one;
    animation-duration: 3s;
    animation-fill-mode: both;
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements {
    position: relative;
    height: 50%;
    width: 6rem;
    margin: 0 1rem;
    border-radius: 2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(1) {
    background-image: url('../../../Images/Company/cTwo.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(2) {
    background-image: url('../../../Images/Company/cThree.jpg');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(3) {
    background-image: url('../../../Images/Company/cFour.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(4) {
    background-image: url('../../../Images/Company/cFive.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(5) {
    background-image: url('../../../Images/Company/cSix.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(6) {
    background-image: url('../../../Images/Company/cSeven.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(7) {
    background-image: url('../../../Images/Company/cEight.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(8) {
    background-image: url('../../../Images/Company/cNine.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(9) {
    background-image: url('../../../Images/Company/cTen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(10) {
    background-image: url('../../../Images/Company/cEleven.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    right: -20rem;
    opacity: 0;
    height: 48%;
    width: auto;
    animation-name: home-six-box-two;
    animation-duration: 3s;
    animation-fill-mode: both;
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements {
    position: relative;
    height: 50%;
    width: 6rem;
    margin: 0 1rem;
    border-radius: 2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(1) {
    background-image: url('../../../Images/Company/cTwelve.jpg');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(2) {
    background-image: url('../../../Images/Company/cThirteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(3) {
    background-image: url('../../../Images/Company/cFourteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(4) {
    background-image: url('../../../Images/Company/cFifteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(5) {
    background-image: url('../../../Images/Company/cSixteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(6) {
    background-image: url('../../../Images/Company/cSeventeen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(7) {
    background-image: url('../../../Images/Company/cEighteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(8) {
    background-image: url('../../../Images/Company/cNinteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(9) {
    background-image: url('../../../Images/Company/cTwenty.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(10) {
    background-image: url('../../../Images/Company/cEleven.png');
 }
  @keyframes home-six-box-one {
    100% {
      opacity: 1;
      left: 0;
   }
 }
  @keyframes home-six-box-two {
    100% {
      opacity: 1;
      right: 0;
   }
 }
  #home-container #home-six-container #home-six-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: -2rem;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn {
    position: relative;
    width: fit-content;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 var(--shadow-two);
    transition: color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn:hover {
    color: white;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn:hover::after {
    width: 100%;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -2;
    background-color: white;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 0.5rem;
    background-color: var(--theme-one);
    transition: width 0.5s ease-in-out;
    z-index: -1;
 }
  #home-container #home-seven-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-seven-container #home-seven-mid-title {
    display: flex;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    padding: 0 2rem;
    margin-top: 5rem;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid {
    position: relative;
    height: auto;
    width: 100%;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left {
    display: block;
    align-items: center;
    justify-content: center;
    position: relative;
    height: auto;
    width: 100%;
    padding: 3rem 2.5rem 0 2.5rem;
    opacity: 0;
    animation-name: home-seven-left;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box {
    display: block;
    height: auto;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square {
    height: 15rem;
    width: 49%;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: white;
    box-shadow: 1px 1px 25px 0 var(--shadow);
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover {
    background-color: var(--footer-one);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover .home-seven-left-top .home-seven-left-top-two {
    font-weight: 100;
    color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover .home-seven-left-bottom {
    color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top {
    display: flex;
    height: 5rem;
    width: 100%;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top .home-seven-left-top-one {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-family: fontReg;
    font-size: 2.75rem;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top .home-seven-left-top-two {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-left: 1.5rem;
    font-family: fontReg;
    font-size: 2rem;
    color: var(--theme-three);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-bottom {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: grey;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(1) .home-seven-mid-left-box-element-square:nth-child(1) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-four);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(1) .home-seven-mid-left-box-element-square:nth-child(2) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-five);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) {
    margin-top: 0;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) .home-seven-mid-left-box-element-square:nth-child(1) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-six);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) .home-seven-mid-left-box-element-square:nth-child(2) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-two);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right {
    position: relative;
    right: -20rem;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0rem 2rem 5rem 2rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-seven-right;
    animation-duration: 1s;
    animation-fill-mode: both;
    background-image: url('../../../Images/Dizital/bgEight.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-subtitle {
    padding: 2rem 0;
    font-size: 1.7rem;
    box-sizing: border-box;
    color: grey;
    letter-spacing: 0.75px;
    line-height: 2.5rem;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn {
    position: relative;
    width: fit-content;
    padding: 1rem 3rem;
    font-size: 1.7rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 var(--shadow-two);
    transition: color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn:hover {
    color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn:hover::after {
    width: 100%;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -2;
    background-color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 0.5rem;
    background-color: var(--theme-one);
    transition: width 0.5s ease-in-out;
    z-index: -1;
 }
  @keyframes home-seven-left {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-seven-right {
    100% {
      right: 0;
      opacity: 1;
   }
 }
  @keyframes home-seven-left-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-eight-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgSix.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-eight-container #home-eight-mid-title {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    padding: 0 2rem;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
 }
  #home-container #home-eight-container #home-eight-mid {
    position: relative;
    height: auto;
    width: 100%;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left {
    position: relative;
    left: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    margin-top: -5rem;
    padding: 0rem 2.5rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-eight-left;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down {
    height: auto;
    padding: 2rem 0;
    letter-spacing: 0.5px;
    font-size: 1.2rem;
    box-sizing: border-box;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin: 1rem 0;
    padding: 0.3rem;
    background-color: white;
    transition: height 0.5s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 3px 3px 15px 0 var(--shadow);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont:hover {
    box-shadow: 3px 3px 15px 0 var(--shadow-two);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-box-title {
    display: flex;
    align-items: center;
    height: auto;
    width: 85%;
    padding: 1rem 1rem;
    overflow: hidden;
    font-size: 1.7rem;
    text-overflow: ellipsis;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    height: 4rem;
    width: 10%;
    min-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-mid-drop-img {
    height: 1.7rem;
    padding: 0.4rem;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-rotate-down {
    transition: transform 0.3s ease-in-out;
    transform: rotate(180deg);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-rotate-up {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-content {
    width: 100%;
    padding: 1rem;
    margin-top: 0;
    font-size: 1.5rem;
    letter-spacing: 1px;
    line-height: 2rem;
    color: grey;
    animation-name: mountPop;
    animation-duration: 0.5s;
    animation-fill-mode: both;
 }
  @keyframes mountPop {
    0% {
      margin-top: -5%;
      opacity: 0.5;
   }
    100% {
      margin-top: 0;
      opacity: 1;
   }
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    opacity: 0;
    animation-name: home-eight-right;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-right-img {
    height: 30rem;
    margin-top: -5rem;
    animation-name: home-eight-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
 }
  @keyframes home-eight-right {
    100% {
      right: 0;
      opacity: 1;
   }
 }
  @keyframes home-eight-left {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-eight-right-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-nine-container {
    height: auto;
    width: 100%;
    padding: 3rem 1.5rem;
    box-sizing: border-box;
 }
  #home-container #home-nine-container #home-nine-title {
    width: 100%;
    text-align: center;
    padding: 0rem 0 0rem 0;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 3rem;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-nine-container #home-nine-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin: 2rem auto 0 auto;
    height: 40rem;
    width: auto;
    overflow-y: hidden;
    overflow-x: scroll;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 10rem;
    height: 100%;
    width: auto;
    padding: 1rem 0;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;
    animation-name: home-nine-bottom;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar {
    display: block;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar-track {
    border-radius: 0.5rem;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar-thumb {
    background-color: var(--shadow);
    border-radius: 0.5rem;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar-track-piece {
    border-radius: 0.5rem;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi {
    display: block;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    height: 35rem;
    width: 30rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
    padding: 1rem 1.5rem 0 1.5rem;
    box-shadow: 5px 5px 15px 0 #ddd;
    background-color: white;
    box-sizing: border-box;
    cursor: default;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-icon {
    height: 13rem;
    width: 100%;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-title {
    height: auto;
    width: 100%;
    padding: 0.75rem 0;
    text-align: center;
    font-size: 2.2rem;
    font-weight: bolder;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-subtitle {
    height: auto;
    width: 100%;
    font-size: 1.7rem;
    line-height: 2rem;
    text-align: center;
    color: grey;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn {
    width: fit-content;
    bottom: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    padding: 0.1rem 0.5rem;
    box-sizing: border-box;
    transition: font-weight 0s ease-in-out;
    cursor: pointer;
 }
  @keyframes home-nine-bottom {
    100% {
      left: 0;
   }
 }
  #home-container #home-ten-container {
    flex-direction: column;
    padding: 3rem 2.5rem 3rem 2.5rem;
 }
  #home-container #home-ten-container .home-ten-indi {
    height: 32rem;
    width: 100%;
    padding: 2.5rem 2rem 0rem 2rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 0px 20px 0 var(--shadow);
    transition: transform 2s ease-in-out;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box {
    height: 13rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon {
    height: 13rem;
    width: auto;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon::before {
    height: 15.5rem;
    width: 15.5rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon::after {
    height: 14rem;
    width: 14rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-title {
    margin: 2rem auto 0.75rem auto;
    font-size: 2.5rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-subtitle {
    font-size: 1.7rem;
    letter-spacing: 0.5px;
    line-height: 2rem;
 }
  #home-container #home-ten-container .home-ten-indi:hover .home-ten-icon-box .home-ten-icon::before {
    transform: rotate(270deg);
 }
  #home-container #home-ten-container .home-ten-indi:hover .home-ten-icon-box .home-ten-icon::after {
    transform: rotate(630deg);
 }
  #home-container #home-ten-container .home-ten-indi:nth-child(1) {
    transform: translateX(30rem);
 }
  #home-container #home-ten-container .home-ten-indi:nth-child(2) {
    transform: translateX(-30rem);
 }
  #home-container #home-ten-container .home-ten-indi:nth-child(3) {
    transform: translateX(30rem);
 }
  #home-container #home-ten-container .home-ten-indi-anime:nth-child(1) {
    transform: translateX(0rem);
 }
  #home-container #home-ten-container .home-ten-indi-anime:nth-child(2) {
    transform: translateX(0rem);
 }
  #home-container #home-ten-container .home-ten-indi-anime:nth-child(3) {
    transform: translateX(0rem);
 }
}
@media screen and (max-width: 1025px) and (max-height: 700px) {
  #home-container #home-one-container {
    height: 40rem;
    padding-top: 23rem;
 }
  #home-container #home-two-container {
    height: auto;
 }
  #home-container #home-two-container #home-two-top-box {
    height: 5rem;
 }
  #home-container #home-three-container {
    height: 100vh;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right {
    padding: 0rem 1.5rem 2rem 1.5rem;
 }
  #home-container #home-five-container {
    height: 100vh;
 }
  #home-container #home-six-container {
    height: 80vh;
 }
}

@media screen and (max-width: 600px) {
  #home-container {
    height: auto;
    width: 100%;
    padding-top: 0rem;
    overflow-x: hidden;
 }
  #home-container #home-one-container {
    display: flex;
    position: relative;
    height: 100vh;
    width: 100%;
    padding: 0 1rem;
    padding-top: 20rem;
    padding-bottom: 3rem;
    background-image: url('../../../Images/Dizital/bgOne.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-one-container #home-one-left-box {
    height: 10rem;
    width: 90%;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-top {
    display: flex;
    align-items: center;
    height: 35%;
    width: 100%;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 1.5rem;
    color: var(--theme-one);
    margin-bottom: -1rem;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom {
    display: flex;
    align-items: center;
    height: 25%;
    width: 100%;
    margin-top: 1rem;
    font-size: 1.1rem;
    color: #fff;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    height: auto;
    margin-top: 1rem;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn {
    position: relative;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    margin-top: 0rem;
    margin-right: 0.5rem;
    /* box-shadow: 3px 3px 15px 0 var(--shadow); */
    border: 2px solid #fff;
    font-weight: 600;
    font-size: 1.1rem;
    color: #fff;
    transition: border-color 0.5 ease-in-out, background-color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn a {
    text-decoration: none;
    color: #fff;
    font-weight: 100;
    transition: color 0s ease-in-out;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn:hover {
    color: white;
    font-weight: 100;
    background-color: var(--theme-one);
    border-color: var(--theme-one);
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-bottom-btn-box .home-one-left-bottom-btn:hover a {
    color: white;
    font-weight: 100;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-mid {
    display: flex;
    align-items: center;
    position: relative;
    height: fit-content;
    font-family: fontReg;
    font-weight: bolder;
    width: fit-content;
    font-size: 1.43rem;
    color: var(--theme-two);
    padding-right: 1rem;
    overflow-x: hidden;
 }
  #home-container #home-one-container #home-one-left-box #home-one-left-mid .home-one-left-mid-text {
    height: 2rem;
  }
  #home-container #home-one-container #home-one-anime-one {
    position: absolute;
    top: 38rem;
    left: -2rem;
    height: 30rem;
    width: 30rem;
    background-image: url('../../../Images/Sammy/one.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation-name: one-anime-one;
    animation-duration: 8s;
    animation-iteration-count: infinite;
 }
  @keyframes one-anime-one {
    0% {
      transform: translateY(0);
   }
    50% {
      transform: translateY(2rem);
   }
    100% {
      transform: translateY(0);
   }
 }
  #home-container #home-one-container #home-one-anime-two {
    display: none;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    height: 20rem;
    width: 20rem;
    background-image: url('../../../Images/Sammy/five.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation-name: one-anime-two;
    animation-duration: 12s;
    animation-iteration-count: infinite;
 }
  @keyframes one-anime-two {
    0% {
      transform: translateX(0);
   }
    50% {
      transform: translateX(5rem);
   }
    100% {
      transform: translateX(0);
   }
 }
  #home-container #home-two-container {
    height: 80vh;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgEight.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-two-container #home-two-top-box {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 8rem;
    width: 100%;
    padding-left: unset;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
 }
  #home-container #home-two-container #home-two-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 5rem 1rem;
    height: 25rem;
    width: 93%;
    overflow-x: unset;
 }
  #home-container #home-two-container #home-two-box::-webkit-scrollbar {
    display: none;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid {
    display: flex;
    align-items: center;
    position: absolute;
    left: 10rem;
    height: 100%;
    width: 100%;
    padding: 1rem 0;
    scroll-behavior: smooth;
    overflow-x: scroll;
    animation-name: home-two-box-mid;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi {
    display: block;
    align-items: center;
    flex-flow: column;
    justify-content: flex-start;
    height: 100%;
    width: 20rem;
    padding: 2rem 1.5rem 1rem 1.5rem;
    margin: 0 0.5rem;
    text-align: center;
    border-radius: 0.7rem;
    background-color: white;
    box-shadow: 3px 3px 25px 0 var(--shadow);
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(1) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/nine.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(2) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/six.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(3) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/seven.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(4) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/eight.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(5) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/seven.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(6) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/five.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(7) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/six.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(8) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/seven.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi:nth-child(9) .home-two-box-indi-icon {
    background-image: url('../../../Images/Sammy/eight.png');
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9rem;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 1.5rem;
    font-family: fontReg;
    font-weight: 0;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-subtitle {
    height: auto;
    width: 17rem;
    margin: 0 auto;
    font-size: 1.2rem;
    color: grey;
    box-sizing: border-box;
 }
  #home-container #home-two-container #home-two-box #home-two-box-mid .home-two-box-indi .home-two-box-indi-btn {
    font-size: 0.95rem;
    bottom: 1rem;
    padding: 0.2rem 0.5rem;
 }
  @keyframes home-two-box-mid {
    100% {
      left: 0;
   }
 }
  #home-container #home-three-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 85vh;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgNine.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-three-container #home-three-mid {
    position: relative;
    height: 100%;
    width: 100%;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right {
    position: absolute;
    left: -20rem;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 4rem 1.5rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-three-right;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-subtitle {
    padding: 2rem 0;
    font-size: 1.1rem;
    box-sizing: border-box;
    color: grey;
    letter-spacing: 0.5px;
    line-height: 1.7rem;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn {
    position: relative;
    width: fit-content;
    padding: 1rem 3rem;
    font-size: 1.1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 var(--shadow-two);
    transition: color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn:hover {
    color: white;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn:hover::after {
    width: 100%;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -2;
    background-color: white;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right #home-three-mid-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 0.5rem;
    background-color: var(--theme-one);
    transition: width 0.5s ease-in-out;
    z-index: -1;
 }
  @keyframes home-three-right {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-three-left-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-four-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 5rem 0 0rem 0;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgFive.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-four-container #home-four-mid {
    position: relative;
    height: auto;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-left {
    position: relative;
    height: auto;
    width: 100%;
    padding: 0rem 1.5rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-four-left;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-left #home-four-mid-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-left #home-four-mid-subtitle {
    padding: 2rem 0;
    letter-spacing: 0.5px;
    font-size: 1.2rem;
    line-height: 1.7rem;
    box-sizing: border-box;
    color: grey;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-right {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -15rem;
    top: 0;
    height: 100%;
    width: 50%;
    opacity: 0;
    animation-name: home-four-right;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-four-container #home-four-mid #home-four-mid-right-img {
    height: 35rem;
    animation-name: home-four-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
 }
  @keyframes home-four-right {
    100% {
      right: 0;
      opacity: 1;
   }
 }
  @keyframes home-four-left {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-four-right-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-five-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: row wrap;
    height: auto;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-top-box {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 8rem;
    width: 100%;
    padding: 0 1.5rem;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
 }
  #home-container #home-five-container #home-five-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 27rem;
    width: 100%;
    padding: 0 1.5rem 0rem 1.5rem;
    margin-bottom: 3rem;
    box-sizing: border-box;
    overflow-x: scroll;
    overflow-y: hidden;
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
    position: absolute;
    left: 0;
    height: 100%;
    padding: 1rem 1rem;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;
    animation-name: home-five-box-mid;
    animation-duration: 2s;
    animation-fill-mode: both;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi {
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    justify-content: flex-start;
    height: auto;
    min-height: 22rem;
    width: 18rem;
    padding: 1rem 1.5rem;
    margin: 0 0.5rem;
    text-align: left;
    border-radius: 0.7rem;
    background-color: white;
    box-shadow: 1px 1px 15px 0 var(--shadow);
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top {
    display: flex;
    align-items: center;
    height: 3rem;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top .home-five-box-indi-top-left {
    width: auto;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top .home-five-box-indi-top-left .home-five-box-indi-left-icon {
    height: 1.5rem;
    width: 1.5rem;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-top .home-five-box-indi-top-right {
    font-size: 1.1rem;
    padding-left: 1rem;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-mid {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 90%;
    padding: 0.5rem 0 1.5rem 0;
    font-size: 1.1rem;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
    height: auto;
    width: fit-content;
    box-sizing: border-box;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    width: fit-content;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-left .home-five-box-indi-bottom-left-icon {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right {
    margin: 0rem 0 0 1rem;
    height: 4rem;
    width: fit-content;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right .home-five-box-indi-bottom-right-title {
    font-size: 1.5rem;
    font-family: fontReg;
    color: var(--theme-three);
    width: 100%;
 }
  #home-container #home-five-container #home-five-box #home-five-box-mid .home-five-box-indi .home-five-box-indi-bottom .home-five-box-indi-bottom-right .home-five-box-indi-bottom-right-subtitle {
    font-size: 1.1rem;
    width: 100%;
    cursor: pointer;
    color: grey;
    font-family: fontLight;
 }
  @keyframes home-five-box-mid {
    100% {
      left: 0;
   }
 }
  #home-container #home-six-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: row wrap;
    position: relative;
    height: 55vh;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgNine.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-six-container #home-six-title {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 5rem;
    width: 100%;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    padding: 0 1.5rem;
    color: var(--theme-one);
 }
  #home-container #home-six-container #home-six-box {
    position: relative;
    height: 15rem;
    width: 100%;
    margin-top: -3rem;
 }
  #home-container #home-six-container #home-six-box #home-six-box-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: -20rem;
    opacity: 0;
    height: 48%;
    width: auto;
    animation-name: home-six-box-one;
    animation-duration: 3s;
    animation-fill-mode: both;
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements {
    position: relative;
    height: 50%;
    width: 6rem;
    margin: 0 1rem;
    border-radius: 2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(1) {
    background-image: url('../../../Images/Company/cTwo.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(2) {
    background-image: url('../../../Images/Company/cThree.jpg');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(3) {
    background-image: url('../../../Images/Company/cFour.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(4) {
    background-image: url('../../../Images/Company/cFive.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(5) {
    background-image: url('../../../Images/Company/cSix.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(6) {
    background-image: url('../../../Images/Company/cSeven.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(7) {
    background-image: url('../../../Images/Company/cEight.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(8) {
    background-image: url('../../../Images/Company/cNine.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(9) {
    background-image: url('../../../Images/Company/cTen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-one .home-six-box-elements:nth-child(10) {
    background-image: url('../../../Images/Company/cEleven.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    right: -20rem;
    opacity: 0;
    height: 48%;
    width: auto;
    animation-name: home-six-box-two;
    animation-duration: 3s;
    animation-fill-mode: both;
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements {
    position: relative;
    height: 50%;
    width: 6rem;
    margin: 0 1rem;
    border-radius: 2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(1) {
    background-image: url('../../../Images/Company/cTwelve.jpg');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(2) {
    background-image: url('../../../Images/Company/cThirteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(3) {
    background-image: url('../../../Images/Company/cFourteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(4) {
    background-image: url('../../../Images/Company/cFifteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(5) {
    background-image: url('../../../Images/Company/cSixteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(6) {
    background-image: url('../../../Images/Company/cSeventeen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(7) {
    background-image: url('../../../Images/Company/cEighteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(8) {
    background-image: url('../../../Images/Company/cNinteen.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(9) {
    background-image: url('../../../Images/Company/cTwenty.png');
 }
  #home-container #home-six-container #home-six-box #home-six-box-two .home-six-box-elements:nth-child(10) {
    background-image: url('../../../Images/Company/cEleven.png');
 }
  @keyframes home-six-box-one {
    100% {
      opacity: 1;
      left: 0;
   }
 }
  @keyframes home-six-box-two {
    100% {
      opacity: 1;
      right: 0;
   }
 }
  #home-container #home-six-container #home-six-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-top: -2rem;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn {
    position: relative;
    width: fit-content;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 var(--shadow-two);
    transition: color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn:hover {
    color: white;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn:hover::after {
    width: 100%;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -2;
    background-color: white;
 }
  #home-container #home-six-container #home-six-btn-box #home-six-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 0.5rem;
    background-color: var(--theme-one);
    transition: width 0.5s ease-in-out;
    z-index: -1;
 }
  #home-container #home-seven-container {
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-seven-container #home-seven-mid-title {
    display: flex;
    justify-content: center;
    height: auto;
    width: 100%;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    padding: 0 1.5rem;
    margin-top: 5rem;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid {
    position: relative;
    height: auto;
    width: 100%;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -20rem;
    top: 0;
    height: auto;
    width: 100%;
    padding: 3rem 2.5rem 0 2.5rem;
    opacity: 0;
    animation-name: home-seven-left;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    height: auto;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square {
    height: 50%;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: white;
    box-shadow: 1px 1px 25px 0 var(--shadow);
    box-sizing: border-box;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover {
    background-color: var(--footer-one);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover .home-seven-left-top .home-seven-left-top-two {
    font-weight: 100;
    color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square:hover .home-seven-left-bottom {
    color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top {
    display: flex;
    height: 5rem;
    width: 100%;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top .home-seven-left-top-one {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-family: fontReg;
    font-size: 2.75rem;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-top .home-seven-left-top-two {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin-left: 1.5rem;
    font-family: fontReg;
    font-size: 1.3rem;
    color: var(--theme-three);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element .home-seven-mid-left-box-element-square .home-seven-left-bottom {
    padding-top: 1rem;
    font-size: 1.1rem;
    color: grey;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(1) .home-seven-mid-left-box-element-square:nth-child(1) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-four);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(1) .home-seven-mid-left-box-element-square:nth-child(2) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-five);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) {
    margin-top: 0;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) .home-seven-mid-left-box-element-square:nth-child(1) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-six);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-left .home-seven-mid-left-box .home-seven-mid-left-box-element:nth-child(2) .home-seven-mid-left-box-element-square:nth-child(2) .home-seven-left-top .home-seven-left-top-one {
    color: var(--theme-two);
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right {
    position: relative;
    right: -20rem;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0rem 1.5rem 3rem 1.5rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-seven-right;
    animation-duration: 1s;
    animation-fill-mode: both;
    background-image: url('../../../Images/Dizital/bgEight.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-subtitle {
    padding: 2rem 0;
    font-size: 1.2rem;
    box-sizing: border-box;
    color: grey;
    letter-spacing: 0.5px;
    line-height: 1.7rem;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn {
    position: relative;
    width: fit-content;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 var(--shadow-two);
    transition: color 0.5s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn:hover {
    color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn:hover::after {
    width: 100%;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    z-index: -2;
    background-color: white;
 }
  #home-container #home-seven-container #home-seven-mid #home-seven-mid-right #home-seven-mid-btn::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 0.5rem;
    background-color: var(--theme-one);
    transition: width 0.5s ease-in-out;
    z-index: -1;
 }
  @keyframes home-seven-left {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-seven-right {
    100% {
      right: 0;
      opacity: 1;
   }
 }
  @keyframes home-seven-left-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-eight-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    background-image: url('../../../Images/Dizital/bgSix.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 }
  #home-container #home-eight-container #home-eight-mid-title {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    padding: 0 1.5rem;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
 }
  #home-container #home-eight-container #home-eight-mid {
    position: relative;
    height: auto;
    width: 100%;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left {
    position: relative;
    left: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    margin-top: -5rem;
    padding: 0rem 1.5rem;
    opacity: 0;
    box-sizing: border-box;
    animation-name: home-eight-left;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-title {
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down {
    height: auto;
    padding: 2rem 0;
    letter-spacing: 0.5px;
    font-size: 1.2rem;
    box-sizing: border-box;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    margin: 1rem 0;
    padding: 0.3rem;
    background-color: white;
    transition: height 0.5s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 3px 3px 15px 0 var(--shadow);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont:hover {
    box-shadow: 3px 3px 15px 0 var(--shadow-two);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-box-title {
    display: flex;
    align-items: center;
    height: auto;
    width: 75%;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    height: 2.5rem;
    width: 10%;
    min-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-mid-drop-img {
    height: 1.5rem;
    padding: 0.4rem;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-rotate-down {
    transition: transform 0.3s ease-in-out;
    transform: rotate(180deg);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-drop-button .home-eight-rotate-up {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-left #home-eight-mid-down .home-eight-mid-box-cont .home-eight-mid-content {
    width: 100%;
    padding: 1rem;
    margin-top: 0;
    font-size: 1.1rem;
    line-height: unset;
    letter-spacing: unset;
    color: grey;
    animation-name: mountPop;
    animation-duration: 0.5s;
    animation-fill-mode: both;
 }
  @keyframes mountPop {
    0% {
      margin-top: -5%;
      opacity: 0.5;
   }
    100% {
      margin-top: 0;
      opacity: 1;
   }
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-right {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -15rem;
    top: 0;
    height: auto;
    width: 100%;
    opacity: 0;
    animation-name: home-eight-right;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-eight-container #home-eight-mid #home-eight-mid-right-img {
    height: 26rem;
    margin-top: -3rem;
    animation-name: home-eight-right-img;
    animation-duration: 7s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
 }
  @keyframes home-eight-right {
    100% {
      right: 0;
      opacity: 1;
   }
 }
  @keyframes home-eight-left {
    100% {
      left: 0;
      opacity: 1;
   }
 }
  @keyframes home-eight-right-img {
    50% {
      transform: translateY(1rem);
   }
    100% {
      transform: translateY(0rem);
   }
 }
  #home-container #home-nine-container {
    height: auto;
    width: 100%;
    padding: 0 1.5rem;
    box-sizing: border-box;
 }
  #home-container #home-nine-container #home-nine-title {
    width: 100%;
    text-align: center;
    padding: 0rem 0 0rem 0;
    font-family: fontReg;
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--theme-one);
    box-sizing: border-box;
 }
  #home-container #home-nine-container #home-nine-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin: 2rem auto 0 auto;
    height: 28rem;
    width: auto;
    overflow-y: hidden;
    overflow-x: scroll;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 10rem;
    height: 100%;
    width: auto;
    padding: 1rem 0;
    scroll-behavior: smooth;
    overflow-x: scroll;
    overflow-y: hidden;
    animation-name: home-nine-bottom;
    animation-duration: 1s;
    animation-fill-mode: both;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar {
    display: block;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar-track {
    border-radius: 0.5rem;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar-thumb {
    background-color: var(--shadow);
    border-radius: 0.5rem;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom::-webkit-scrollbar-track-piece {
    border-radius: 0.5rem;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi {
    display: block;
    align-items: flex-start;
    flex-flow: row wrap;
    justify-content: center;
    position: relative;
    height: 24rem;
    width: 19rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    padding: 1rem 1rem 0 1rem;
    box-shadow: 5px 5px 15px 0 #ddd;
    background-color: white;
    box-sizing: border-box;
    cursor: default;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-icon {
    height: 9rem;
    width: 100%;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-title {
    height: auto;
    width: 100%;
    padding: 0.75rem 0;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bolder;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-subtitle {
    height: auto;
    width: 100%;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: center;
    color: grey;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn {
    width: fit-content;
    font-size: 0.95rem;
    bottom: 1rem;
    right: 1rem;
    padding: 0.1rem 0.5rem;
    box-sizing: border-box;
    transition: font-weight 0s ease-in-out;
    cursor: pointer;
 }
  #home-container #home-nine-container #home-nine-box #home-nine-bottom .home-nine-bottom-indi .home-nine-bottom-indi-btn:hover {
    font-weight: 600;
 }
  @keyframes home-nine-bottom {
    100% {
      left: 0;
   }
 }
  #home-container #home-ten-container {
    flex-direction: column;
    padding: 3rem 1.5rem 3rem 1.5rem;
 }
  #home-container #home-ten-container .home-ten-indi {
    height: 27rem;
    width: 100%;
    padding: 2rem 2rem 0rem 2rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0px 0px 20px 0 var(--shadow);
    transition: transform 2s ease-in-out;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box {
    height: 10rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon {
    height: 9rem;
    width: auto;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon::before {
    height: 10rem;
    width: 10rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-icon-box .home-ten-icon::after {
    height: 11rem;
    width: 11rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-title {
    margin: 1.5rem auto 0.75rem auto;
    font-size: 1.75rem;
 }
  #home-container #home-ten-container .home-ten-indi .home-ten-subtitle {
    font-size: 1.1rem;
    letter-spacing: 0.3px;
    line-height: 1.3rem;
 }
  #home-container #home-ten-container .home-ten-indi:hover .home-ten-icon-box .home-ten-icon::before {
    transform: rotate(270deg);
 }
  #home-container #home-ten-container .home-ten-indi:hover .home-ten-icon-box .home-ten-icon::after {
    transform: rotate(630deg);
 }
  #home-container #home-ten-container .home-ten-indi:nth-child(1) {
    transform: translateX(30rem);
 }
  #home-container #home-ten-container .home-ten-indi:nth-child(2) {
    transform: translateX(-30rem);
 }
  #home-container #home-ten-container .home-ten-indi:nth-child(3) {
    transform: translateX(30rem);
 }
  #home-container #home-ten-container .home-ten-indi-anime:nth-child(1) {
    transform: translateX(0rem);
 }
  #home-container #home-ten-container .home-ten-indi-anime:nth-child(2) {
    transform: translateX(0rem);
 }
  #home-container #home-ten-container .home-ten-indi-anime:nth-child(3) {
    transform: translateX(0rem);
 }
}
@media screen and (max-width: 600px) and (max-height: 700px) {
  #home-container #home-one-container {
    height: 40rem;
    padding-top: 23rem;
 }
  #home-container #home-one-container #home-one-anime-one {
    top: 19rem;
    left: 1rem;
    height: 25rem;
    width: 25rem;
 }
  #home-container #home-two-container {
    height: auto;
 }
  #home-container #home-two-container #home-two-top-box {
    height: 5rem;
 }
  #home-container #home-three-container {
    height: 100vh;
 }
  #home-container #home-three-container #home-three-mid #home-three-mid-right {
    padding: 0rem 1.5rem 2rem 1.5rem;
 }
  #home-container #home-five-container {
    height: 100vh;
 }
  #home-container #home-six-container {
    height: 80vh;
 }
}
