#pro-container {
  height: auto;
  width: auto;
  padding: 5rem 5rem 1rem 5rem;
  background-image: url("../../../Images/Dizital/bgOne.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  margin: 8rem 5rem 5rem 5rem;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 2px 2px 25px 0px var(--shadow);
}
#pro-container #pro-title {
  font-family: fontReg;
  font-size: 3rem;
  color: var(--theme-three);
}
#pro-container #pro-img-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100%;
}
#pro-container #pro-img-cont #pro-img {
  height: 20rem;
  width: auto;
  margin: 0rem auto;
}
#pro-container #pro-subtitle {
  text-indent: 3rem;
  font-family: arialLight;
  font-size: 1.3rem;
  letter-spacing: 0px;
  word-spacing: 1.5px;
  line-height: 1.8rem;
  text-align: justify;
}
#pro-container #pro-lists-conts {
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
#pro-container #pro-lists-conts .pro-lists-container {
  height: auto;
  width: auto;
  width: 31%;
  margin: 1rem 0;
  box-sizing: border-box;
}
#pro-container #pro-lists-conts .pro-lists-container .pro-lists-title {
  font-size: 1.75rem;
  font-family: fontReg;
  color: var(--theme-three);
}
#pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container {
  height: auto;
  width: auto;
  padding: 1rem;
}
#pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container .pro-lists-subtitle {
  display: flex;
  position: relative;
  width: fit-content;
  font-size: 1.15rem;
  letter-spacing: 0.1px;
  word-spacing: 1.5px;
  font-family: arialReg;
  padding: 0.5rem 1rem 0.5rem 0;
}
#pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container .pro-lists-subtitle .pro-lists-bullet {
  padding: 0 1rem 0 0rem;
}
#pro-container #pro-lists-conts .pro-lists-container .pro-list-cont-column {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: auto;
}
#pro-container #pro-lists-conts .pro-lists-container .pro-list-cont-column .pro-list-column {
  width: 31%;
}
#pro-container #pro-lists-conts #pro-lists-container-column {
  max-width: 32.5%;
}
#pro-container #pro-lists-conts #pro-list-long {
  width: auto;
}
#pro-container #pro-subcontent-container {
  height: auto;
  width: 100%;
  margin: 4rem 0;
}
#pro-container #pro-subcontent-container .pro-subcontent-title {
  font-size: 2rem;
  color: var(--theme-three);
}
#pro-container #pro-subcontent-container .pro-subcontent-subtitle {
  margin: 1.5rem 0;
  position: relative;
  font-size: 1.1rem;
  font-family: arialLight;
  letter-spacing: 0.1px;
  text-indent: 3rem;
  color: grey;
  text-align: justify;
}

@media screen and (max-width: 1025px) {
  #pro-container {
    height: auto;
    width: auto;
    padding: 10rem 2rem;
    margin: 0;
    background-image: url("../../../Images/Dizital/bgOne.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    overflow-x: hidden;
    box-shadow: none;
  }
  #pro-container #pro-title {
    font-family: fontReg;
    font-size: 3rem;
  }
  #pro-container #pro-img-cont #pro-img {
    height: 25rem;
  }
  #pro-container #pro-subtitle {
    font-size: 1.75rem;
    letter-spacing: 0.75px;
    line-height: 2.5rem;
  }
  #pro-container #pro-lists-conts {
    display: block;
    margin-top: 2rem;
  }
  #pro-container #pro-lists-conts .pro-lists-container {
    height: auto;
    width: 100%;
    margin: 2rem 0;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-title {
    font-family: fontReg;
    font-size: 2.5rem;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container {
    height: auto;
    width: 100%;
    padding: 1rem 0;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container .pro-lists-subtitle {
    display: flex;
    position: relative;
    font-size: 1.6rem;
    letter-spacing: 0px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container .pro-lists-subtitle .pro-lists-bullet {
    padding: 0 1rem 0 0rem;
  }
  #pro-container #pro-subcontent-container {
    height: auto;
    width: 100%;
    margin: 3rem 0;
  }
  #pro-container #pro-subcontent-container .pro-subcontent-title {
    font-family: fontReg;
    font-size: 2.5rem;
  }
  #pro-container #pro-subcontent-container .pro-subcontent-subtitle {
    margin: 1.5rem 0;
    position: relative;
    font-size: 1.75rem;
    text-indent: 2rem;
    letter-spacing: 0.75px;
    line-height: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  #pro-container {
    height: auto;
    width: auto;
    padding: 7rem 1.5rem;
    margin: 0;
    background-image: url("../../../Images/Dizital/bgOne.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    overflow-x: hidden;
    box-shadow: none;
  }
  #pro-container #pro-title {
    font-family: fontReg;
    font-size: 2.5rem;
  }
  #pro-container #pro-img-cont #pro-img {
    height: 25rem;
    margin-top: 0;
  }
  #pro-container #pro-subtitle {
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    line-height: 1.8rem;
  }
  #pro-container #pro-lists-conts {
    display: block;
    margin-top: 2rem;
  }
  #pro-container #pro-lists-conts .pro-lists-container {
    height: auto;
    width: 100%;
    margin: 2rem 0;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-title {
    font-family: fontReg;
    font-size: 1.55rem;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container {
    height: auto;
    width: 100%;
    padding: 1rem 0;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container .pro-lists-subtitle {
    display: flex;
    position: relative;
    font-size: 1.2rem;
    letter-spacing: 0px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
  #pro-container #pro-lists-conts .pro-lists-container .pro-lists-subtitle-container .pro-lists-subtitle .pro-lists-bullet {
    padding: 0 1rem 0 0rem;
  }
}
